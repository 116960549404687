<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <div class="news-list-container">
    <TopBg title="新闻中心" sub-title="在这里读懂智先生" :bg="topBg" />
    <div class="main-content">
      <div class="tab-container">
        <div
          class="tab-item"
          :class="{ active: type === 1 }"
          @click="handleTabClick(1)"
        >
          全部新闻
        </div>
        <div
          class="tab-item"
          :class="{ active: type === 2 }"
          @click="handleTabClick(2)"
        >
          公司新闻
        </div>
        <div
          class="tab-item"
          :class="{ active: type === 3 }"
          @click="handleTabClick(3)"
        >
          行业动态
        </div>
      </div>
      <div class="news-grid">
        <router-link
          class="news-card"
          v-for="(item, index) in newsList"
          :key="index"
          :to="'/news-detail?id=' + item.id"
          target="_blank"
        >
          <div class="news-date">
            <span>{{ item.createTime.substr(5, 5) }}</span>
          </div>
          <div class="card-content">
            <h3>{{ item.title }}</h3>
            <p class="news-desc">{{ item.digest }}</p>
          </div>
          <img :src="item.pictures" class="news-image" alt="news image" />
        </router-link>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        @current-change="getNewsList"
        @prev-click="getNewsList"
        @next-click="getNewsList"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TopBg from "../common/TopBg.vue";
import { getNewsList } from "@/api/news.js";

export default {
  name: "NewsDetail",
  components: {
    TopBg,
  },
  data() {
    return {
      topBg: require("../../assets/news/bg.png"),
      newsList: [],
      type: 1,
      total: 0,
      pageNum: 1,
      pageSize: 6,
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$route.hash) {
          this.type = 3;
        } else {
          this.type = 1;
        }
      },
    },
  },
  created() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      let type = "";
      if (this.type == 1) {
        type = "";
      }
      if (this.type == 2) {
        type = "3";
      }
      if (this.type == 3) {
        type = "2";
      }
      let res = await getNewsList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: type,
        releaseStatus: 0,
      });
      this.newsList = res.rows;
      this.total = res.total;
      // 平滑滚动到顶部
      window.scrollTo({
        top: 0,
      });
    },
    handleTabClick(type) {
      this.type = type;
      this.getNewsList();
    },
  },
};
</script>

<style scoped>
.news-list-container {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
  padding-bottom: 16px;
}

.tab-container {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  padding: 8px;
}

.tab-item {
  flex: 1;
  color: #50627f;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  line-height: 46px;
  border-radius: 8px;
  background-color: #386ec618;
}
.tab-item:hover {
  transform: scale(1.01);
  color: #1cb3b8;
}

.tab-item.active {
  background-color: #1cb3b8;
  color: #fff;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
}

.news-card {
  overflow: hidden;
  position: relative;
  border-bottom: 1px dashed #929eb0;
  display: flex;
  align-items: flex-start;
  padding: 32px 0;
}
.news-card:hover .news-image {
  transform: scale(1.02);
}

.news-card:hover h3 {
  color: #1cb3b8;
}
.news-card:hover .news-date {
  color: #1cb3b8;
}

.news-image {
  width: 376px;
  height: 200px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  transition: all 0.3s ease;
  overflow: hidden;
}

.card-content {
  padding: 0px 16px 0 32px;
  text-align: left;
  flex: 1;
}

h3 {
  color: #1f2329;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}

.news-desc {
  color: #50627f;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-date {
  color: #1f2329;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 148px;
  transition: all 0.3s ease;
}
</style>
<style>
.news-list-container .el-pagination {
  margin-top: 64px !important;
}

.news-list-container .el-pagination button,
.news-list-container .el-pager li {
  background: #f6f8fd !important;
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.news-list-container .el-pager li.active {
  color: #1cb3b8;
}
</style>
<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/logo.png" alt="Logo" />
      </router-link>
    </div>
    <div class="header-content main-content">
      <div class="menu-toggle" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav class="nav-menu" :class="{ active: isMenuOpen }">
        <ul>
          <li class="submenu" :class="{ active: isParentActive.smart }">
            <a
              href="#"
              @click.prevent="toggleSubmenu('smart')"
              :class="{ active: isParentActive.smart }"
              >智维</a
            >
            <ul
              class="dropdown popper-class"
              :class="{ show: openSubmenu === 'smart' }"
            >
              <li>
                <router-link to="/smart" active-class="active"
                  >数字化运维监控系统</router-link
                >
              </li>
              <li>
                <router-link to="/it-service" active-class="active"
                  >IT服务流程管理系统</router-link
                >
              </li>
              <li>
                <router-link to="/digital-management" active-class="active"
                  >数字网信管理系统</router-link
                >
              </li>
            </ul>
          </li>
          <li class="submenu" :class="{ active: isParentActive.medical }">
            <a
              href="#"
              @click.prevent="toggleSubmenu('medical')"
              :class="{ active: isParentActive.medical }"
              >医智信</a
            >
            <ul
              class="dropdown popper-class"
              :class="{ show: openSubmenu === 'medical' }"
            >
              <li>
                <router-link to="/medical" active-class="active"
                  >医保基金智能监管系统</router-link
                >
              </li>
              <li>
                <router-link to="/medical-control" active-class="active"
                  >医保结算清单质控系统</router-link
                >
              </li>
              <li>
                <router-link to="/drg-platform" active-class="active"
                  >DRG/DIP院内综合管理平台</router-link
                >
              </li>
              <li>
                <router-link to="/internet-hospital" active-class="active"
                  >互联网医院</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/news" active-class="active">新闻中心</router-link>
          </li>
          <li>
            <router-link to="/about" active-class="active"
              >关于我们</router-link
            >
          </li>
          <li>
            <router-link to="/join" active-class="active">加入我们</router-link>
          </li>
          <li>
            <router-link to="/free" active-class="active">免费试用</router-link>
          </li>
        </ul>
      </nav>
      <div class="contact">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          fill="none"
        >
          <g clip-path="url(#clip0_2152_909)">
            <circle
              cx="12"
              cy="12.5"
              r="11"
              stroke="url(#paint0_linear_2152_909)"
              stroke-width="1.5"
            />
            <path
              d="M9.66591 7.06152C9.90811 7.06152 10.1312 7.19286 10.2488 7.40461L11.0643 8.87355C11.171 9.06588 11.1761 9.29851 11.0777 9.49528L10.2921 11.0665C10.2921 11.0665 10.5197 12.237 11.4725 13.1898C12.4253 14.1426 13.5919 14.3663 13.5919 14.3663L15.1629 13.5808C15.3598 13.4824 15.5926 13.4875 15.785 13.5944L17.2581 14.4134C17.4696 14.5311 17.6008 14.7541 17.6008 14.9961V16.6873C17.6008 17.5485 16.8008 18.1705 15.9848 17.8952C14.3088 17.3297 11.7073 16.2529 10.0583 14.604C8.40938 12.955 7.33265 10.3535 6.76714 8.67751C6.4918 7.86148 7.11383 7.06152 7.97505 7.06152H9.66591Z"
              stroke="url(#paint1_linear_2152_909)"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_2152_909"
              x1="19.5"
              y1="21.5"
              x2="6"
              y2="2.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1CB3B8" />
              <stop offset="1" stop-color="#386FC6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2152_909"
              x1="18"
              y1="17.5"
              x2="7.5"
              y2="6.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1CB3B8" />
              <stop offset="1" stop-color="#386FC6" />
            </linearGradient>
            <clipPath id="clip0_2152_909">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        <span class="phone"> 400-000-0000 </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSection",
  data() {
    return {
      activeIndex: this.$route.path,
      isMenuOpen: false,
      openSubmenu: null,
    };
  },
  computed: {
    isParentActive() {
      const path = this.$route.path;
      return {
        smart:
          path.includes("/smart") ||
          path.includes("/it-service") ||
          path.includes("/digital-management"),
        medical:
          path.includes("/medical") ||
          path.includes("/medical-control") ||
          path.includes("/drg-platform") ||
          path.includes("/internet-hospital"),
      };
    },
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
      this.isMenuOpen = false;
      this.openSubmenu = null;
    },
  },
  methods: {
    isActive(path) {
      return this.activeIndex === path;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (!this.isMenuOpen) {
        this.openSubmenu = null;
      }
    },
    toggleSubmenu(submenu) {
      this.openSubmenu = this.openSubmenu === submenu ? null : submenu;
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header-content {
  position: relative;
}
.menu-toggle {
  position: absolute;
  right: 10px;
  top: 16px;
}
.nav-menu {
  font-family: "PingFang SC";
}

.nav-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-menu ul li {
  position: relative;
  margin: 0 16px;
}

.nav-menu ul li a {
  display: block;
  color: #1f2329;
  font-size: 16px;
  font-weight: 500;
  line-height: 64px;
  text-decoration: none;
  padding: 0 10px;
}

.nav-menu ul li a:hover,
.nav-menu ul li a.router-link-active,
.nav-menu ul li a.active {
  color: #1cb3b8;
}

.nav-menu ul li a.router-link-active::after,
.nav-menu ul li a.active::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 2px;
  background-color: #1cb3b8;
}

.nav-menu .submenu .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0px 0 0;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  padding: 24px 22px;
  min-width: 200px;
}

.nav-menu .submenu:hover .dropdown {
  display: block;
}

.nav-menu .dropdown li {
  margin: 0 0 24px 0;
}

.nav-menu .dropdown li:last-child {
  margin-bottom: 0;
}

.nav-menu .dropdown li a {
  line-height: 20px;
  color: #50627f;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  text-align: left;
}

.nav-menu .dropdown li a:hover {
  color: #1cb3b8;
}

.nav-menu ul li.submenu > a {
  position: relative;
}

.nav-menu ul li.submenu > a.active,
.nav-menu ul li.submenu:hover > a {
  color: #1cb3b8;
}

.nav-menu .dropdown li a.router-link-active::after,
.nav-menu .dropdown li a.active::after {
  display: none;
}

.nav-menu ul li.active > a::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 2px;
  background-color: #1cb3b8;
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 999;
    border-bottom: 1px solid #fff;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(12.5px);
  }

  .header-content {
    height: 50px;
    padding: 0 15px;
  }

  .logo img {
    height: 50px;
    position: static;
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    cursor: pointer;
    z-index: 1000;
  }

  .menu-toggle span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #1cb3b8;
    transition: all 0.3s ease;
  }

  .nav-menu {
    position: fixed;
    top: 50px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 50px);
    background: #fff;
    transition: all 0.3s ease;
    overflow-y: auto;
  }

  .nav-menu.active {
    left: 0;
    z-index: 999;
  }

  .nav-menu ul {
    flex-direction: column;
    padding: 20px;
  }

  .nav-menu ul li {
    margin: 0;
    border-bottom: 1px solid #eee;
  }

  .nav-menu ul li a {
    line-height: 50px;
    padding: 0;
  }

  .nav-menu .submenu .dropdown {
    position: static;
    display: none;
    box-shadow: none;
    border: none;
    padding: 0 0 0 20px;
    margin: 0;
  }

  .nav-menu .submenu .dropdown.show {
    display: block;
  }

  .nav-menu .dropdown li {
    margin: 0;
    border-bottom: none;
  }

  .nav-menu .dropdown li a {
    line-height: 40px;
  }

  .contact {
    display: none;
  }
}
</style>
<template>
  <div class="partners">
    <div class="partners-content main-content">
      <h2 class="section-title">携手伙伴，共创未来</h2>
      <p class="section-sub-title">服务400+客户</p>
      <PartnerSection />
    </div>
  </div>
</template>

<script>
import PartnerSection from "@/components/common/partners.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Partners",
  components: {
    PartnerSection,
  },
};
</script>

<style scoped>
.partners {
  width: 100%;
  background: #f6f8fd;
  padding: 64px 0;
  overflow: hidden;
}

.partners-content {
  text-align: center;
}

.section-title {
  color: #1f2329;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.section-sub-title {
  color: #50627f;
  font-size: 18px;
  margin-bottom: 32px;
}

.partner-scroll {
  display: flex;
  gap: 80px;
  animation: scroll 40s linear infinite;
}

.scroll-2 {
  animation-delay: -10s;
}

.scroll-3 {
  animation-delay: -20s;
}

.scroll-4 {
  animation-delay: -30s;
}

.partner-row:hover .partner-scroll {
  animation-play-state: paused;
}

.partner-item {
  flex: 0 0 auto;
  transition: all 0.3s ease;
}

.partner-logo {
  width: auto;
  height: 64px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
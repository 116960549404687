<template>
  <div class="about-item">
    <slot name="icon"></slot>
    <div class="about-item__info">
      <div class="title">{{ title }}</div>
      <div class="value">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.about-item {
  display: flex;
  align-items: center;
}
.about-item__info {
  flex: 1;
  margin-left: 32px;
}

.title {
  color: #1f2329;
  text-align: justify;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.value {
  color: #50627f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}
</style>>
<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <div class="banner">
    <div
      class="banner-content"
      :class="{ 'max-feature-item': windowWidth < 1328 ? true : false }"
    >
      <el-carousel
        ref="carousel"
        height="820px"
        :interval="3000"
        :autoplay="true"
        arrow="never"
        @mouseenter.native="delHandleMouseEnter"
      >
        <el-carousel-item v-for="(item, index) in features" :key="index">
          <div
            class="feature-item"
            :style="{ 'background-image': 'url(' + item.icon + ')' }"
          >
            <div
              class="main-content"
              :class="{ 'samll-title': item.link ? true : false }"
            >
              <h3>{{ item.title }}</h3>
              <div class="tags">
                <span
                  v-for="(tag, index) in item.tags"
                  :key="index"
                  class="tag"
                >
                  <svg
                    v-if="item.link"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19.49 1.36963C13.41 5.09963 9 9.80963 7.01 12.1696L2.15 8.35963L0 10.0896L8.39 18.6296C9.83 14.9296 14.41 7.69963 20 2.55963L19.49 1.36963Z"
                      fill="#1CB3B8"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_2152_888)">
                      <path
                        d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM14.004 10.878C13.659 10.353 13.41 9.975 12.462 10.125C10.672 10.409 10.473 10.722 10.388 11.238L10.364 11.394L10.339 11.56C10.242 12.243 10.245 12.501 10.559 12.83C11.824 14.158 12.582 15.115 12.812 15.675C12.924 15.948 13.212 16.775 13.014 17.593C14.2278 17.1095 15.3083 16.3425 16.165 15.356C16.275 14.982 16.355 14.516 16.355 13.952V13.847C16.355 12.925 16.355 12.504 15.703 12.131C15.4935 12.0122 15.2782 11.9037 15.058 11.806C14.691 11.639 14.448 11.53 14.12 11.05C14.08 10.993 14.042 10.936 14.004 10.878ZM10 1.833C7.683 1.833 5.59 2.799 4.104 4.349C4.281 4.472 4.435 4.645 4.541 4.883C4.745 5.34 4.745 5.811 4.745 6.228C4.745 6.556 4.745 6.868 4.85 7.093C4.994 7.401 5.616 7.533 6.165 7.647C6.362 7.689 6.564 7.731 6.748 7.782C7.254 7.922 7.646 8.377 7.959 8.742C8.089 8.893 8.282 9.116 8.379 9.172C8.429 9.136 8.59 8.961 8.669 8.674C8.731 8.454 8.713 8.26 8.624 8.154C8.064 7.494 8.095 6.224 8.268 5.755C8.54 5.016 9.39 5.071 10.012 5.111C10.244 5.126 10.462 5.141 10.626 5.12C11.248 5.042 11.44 4.095 11.575 3.91C11.867 3.51 12.761 2.907 13.315 2.535C12.2715 2.07099 11.142 1.83181 10 1.833Z"
                        fill="#1CB3B8"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2152_888">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="label"> {{ tag }}</span>
                </span>
              </div>
              <green-button
                :href="item.link"
                v-if="item.link"
                class="link-more"
                text="了解更多"
              ></green-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerSection",
  data() {
    return {
      features: [
        {
          icon: require("../../../assets/home/banner_01.png"),
          title: "让数字化永远在线",
          tags: ["智维", "医智信", "医维智友"],
        },
        {
          icon: require("../../../assets/home/banner_02.png"),
          title: "新一代场景式IT综合管理解决方案助力信息部门每一环",
          tags: ["运维精细化", "管理体系化", "结果可视化"],
          link: "/smart",
        },
        {
          icon: require("../../../assets/home/banner_03.png"),
          title: "一站式院内医保管理综合解决方案覆盖门诊住院各场景",
          tags: ["降低违规风险", "促进合理结算", "辅助精准决策"],
          link: "/medical",
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$refs.carousel.handleMouseEnter = () => {};
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    delHandleMouseEnter() {
      this.$refs.carousel.handleMouseEnter = () => {};
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
}

.banner-content {
  width: 100%;
  text-align: left;
  height: 820px;
}

.title {
  font-size: 48px;
}

.feature-item {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.feature-item h3 {
  font-size: 32px;
  margin: 204px 0 0 0;
  text-align: left;
  color: #1f2329;
  font-size: 64px;
  font-weight: 600;
}

.feature-item .samll-title h3 {
  font-size: 56px;
  margin: 184px 0 0 0;
  width: 833px;
}

.tags {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.tags .tag {
  display: flex;
  align-items: center;
  margin-right: 24px;
  height: 20px;
}

.tags .tag .label {
  color: #50627f;
  font-size: 20px;
  font-weight: 500;
  margin-left: 4px;
}

.link-more {
  margin-top: 58px;
}
</style>
<style>
.max-feature-item .el-carousel__indicators--horizontal {
  left: 0 !important;
}
.el-carousel {
  height: 100%;
}

.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.el-carousel__indicators--horizontal {
  bottom: 207px;
  left: calc((100% - 1328px) / 2);
  transform: translateX(0) !important;
}

.el-carousel__button {
  background-color: #929eb0 !important;
  height: 3px !important;
}

.el-carousel__indicator.is-active button {
  background-color: #1cb3b8 !important;
}
</style>
<template>
  <div class="home">
    <Banner />
    <About />
    <Smart />
    <Medical />
    <Partners />
    <News />
  </div>
</template>
<script>
import Banner from "./components/Banner.vue";
import About from "./components/About.vue";
import Smart from "./components/Smart.vue";
import Medical from "./components/Medical.vue";
import Partners from "./components/Partners.vue";
import News from "./components/News.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Banner,
    About,
    Smart,
    Medical,
    Partners,
    News,
  },
};
</script>
import request from '@/utils/request'

export function addDemand(data) {
  return request({
    url: '/olm/intf/',
    method: 'post',
    data
  })
}

export function getPartners() {
  return request({
    url: '/caseManage/list?caseType=1&releaseStatus=0',
    method: 'get',
  })
}
<template>
  <div class="news-detail-container">
    <TopBg title="新闻中心" sub-title="在这里读懂智先生" :bg="topBg" />
    <div class="main-content">
      <div class="content">
        <h1>{{ detail.title }}</h1>
        <div class="date">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M7.99992 15.1654C11.6818 15.1654 14.6666 12.1806 14.6666 8.4987C14.6666 4.8168 11.6818 1.83203 7.99992 1.83203C4.31802 1.83203 1.33325 4.8168 1.33325 8.4987C1.33325 12.1806 4.31802 15.1654 7.99992 15.1654Z"
              stroke="#50627F"
              stroke-width="1.33333"
              stroke-linejoin="round"
            />
            <path
              d="M8.00284 4.5L8.00244 8.50293L10.8289 11.3294"
              stroke="#50627F"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>{{ detail.createTime }}</span>
        </div>
        <div class="article" v-html="detail.content"></div>
      </div>
      <div class="right">
        <h2>您可能感兴趣的文章</h2>
        <div class="news-item" v-for="item in newsList" :key="item.id">
          <router-link class="title" :to="'/news-detail?id=' + item.id">{{
            item.title
          }}</router-link>
          <div class="date">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_2152_3664)">
                <path
                  d="M8.00016 14.6654C11.6821 14.6654 14.6668 11.6806 14.6668 7.9987C14.6668 4.3168 11.6821 1.33203 8.00016 1.33203C4.31826 1.33203 1.3335 4.3168 1.3335 7.9987C1.3335 11.6806 4.31826 14.6654 8.00016 14.6654Z"
                  stroke="#929EB0"
                  stroke-width="1.33333"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00284 4L8.00244 8.00293L10.8289 10.8294"
                  stroke="#929EB0"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2152_3664">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBg from "../common/TopBg.vue";
import { getNewsDetail, getNewsList } from "@/api/news.js";
export default {
  name: "NewsDetail",
  components: {
    TopBg,
  },
  data() {
    return {
      topBg: require("../../assets/news/bg.png"),
      detail: {},
      newsList: [],
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.getNewsDetail();
      },
    },
  },
  created() {
    this.getNewsDetail();
  },
  methods: {
    async getNewsDetail() {
      let res = await getNewsDetail(this.$route.query.id || "1");
      this.detail = res.data;
      this.getNewsList(res.data.type);
    },
    async getNewsList(type) {
      let res = await getNewsList({
        pageSize: 6,
        pageNum: 1,
        type: type,
        releaseStatus: 0,
      });
      this.newsList = res.rows;
    },
  },
};
</script>
<style scoped>
/* 引入assets/css下的css文件 */
@import url("../../assets/css/quill.bubble.css");
@import url("../../assets/css/quill.core.css");
@import url("../../assets/css/quill.snow.css");

.news-detail-container {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  flex: 1;
  padding-bottom: 16px;
  text-align: left;
}

h1 {
  color: #1f2329;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.date {
  display: flex;
  align-items: center;
  margin: 8px 0 32px;
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  /* 27.2px */
}

.date svg {
  margin-right: 4px;
}

.date span {
  display: inline-block;
}

.right {
  width: 376px;
  margin-left: 16px;
  border-radius: 8px;
  background: #eef1f8;
  padding: 24px;
}

.right h2 {
  text-align: left;
  color: #50627f;
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}

.right .title {
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.right .title:hover {
  color: #1cb3b8;
  cursor: pointer;
}

.right .date {
  color: #929eb0;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  /* 23.8px */
  display: flex;
  align-items: center;
}

.right .date svg {
  margin-right: 4px;
}

.right .news-item {
  margin: 4px 0 24px;
}

.right .news-item:last-child {
  margin-bottom: 0px;
}
</style>

<style>
.content .article img {
  width: 100% !important;
  border-radius: 8px;
  margin: 16px 0;
}

.content .article .ql-align-center {
  text-align: center;
}

.content .article {
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  /* 27.2px */
}
</style>
<template>
  <div class="info-item">
    <div class="top">
      <slot name="icon"></slot>
      <div class="title">{{ title }}</div>
    </div>
    <div class="desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    desc: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style scoped>
.info-item {
  border-radius: 8px;
  background: #eef1f8;
  width: 100%;
  padding: 36px 32px;
  text-align: left;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  margin-left: 12px;
  color: #1f2329;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.desc {
  color: #50627f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
  text-align: left;
}
</style>
<template>
  <div class="top-bg" :style="{ 'background-image': 'url(' + bg + ')' }">
    <div class="main-content">
      <div class="title">{{ title }}</div>
      <div class="sub-title">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
// 组件逻辑
export default {
  name: "TopBg",
  props: {
    bg: {
      type: String,
      require: true,
      default: "",
    },
    title: {
      type: String,
      require: true,
      default: "",
    },
    subTitle: {
      type: String,
      require: true,
      default: "",
    },
  },
};
</script>

<style scoped>
.top-bg {
  width: 100%;
  height: 699px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}

.title {
  padding-top: 164px;
  color: #1f2329;
  font-family: "PingFang SC";
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

.sub-title {
  margin-top: 8px;
  color: #50627f;
  font-family: "PingFang SC";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
</style>

import request from '@/utils/request'

export function getNewsList(params) {
    return request({
        url: '/cms/intf/listNews',
        method: 'get',
        params
    })
}

export function getNewsDetail(id) {
    return request({
        url: '/news/' + id,
        method: 'get'
    })
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '@/components/About.vue'
import Smart from '@/components/smart/smart.vue'
import digitalManagement from '@/components/smart/digital-management.vue'
import itService from '@/components/smart/it-service.vue'
import Medical from '@/components/medical/medical.vue'
import drgPlatform from '@/components/medical/drg-platform.vue'
import medicalControl from '@/components/medical/medical-control.vue'
import internetHospital from '@/components/medical/internet-hospital.vue'
import News from '@/components/news/index.vue'
import NewsDetail from '@/components/news/news-detail.vue'
import Home from '@/components/home/index.vue'
import Free from '@/components/free.vue'
import Join from '@/components/Join.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/free',
    name: 'free',
    component: Free
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/smart',
    name: 'smart',
    component: Smart
  },
  {
    path: '/digital-management',
    name: 'digital-management',
    component: digitalManagement
  },
  {
    path: '/it-service',
    name: 'it-service',
    component: itService
  },
  {
    path: '/medical',
    name: 'medical',
    component: Medical
  },
  {
    path: '/drg-platform',
    name: 'drg-platform',
    component: drgPlatform
  },
  {
    path: '/medical-control',
    name: 'medical-control',
    component: medicalControl
  },
  {
    path: '/internet-hospital',
    name: 'internet-hospital',
    component: internetHospital
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    component: NewsDetail
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
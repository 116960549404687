<template>
  <div class="value-item">
    <slot name="icon"></slot>
    <div class="title">{{ title }}</div>
    <div class="desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    desc: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style scoped>
.value-item {
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  width: 100%;
  padding: 32px;
  text-align: center;
}

.title {
  color: #1f2329;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 16px;
}

.desc {
  color: #50627f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
  text-align: left;
}
</style>
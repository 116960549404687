<template>
  <div class="medical-detail-cntainer">
    <TopBg
      title="医保结算清单质控系统"
      sub-title="高效质控，结算无忧。"
      :bg="topBg"
    />
    <div class="main-content">
      <!-- 产品简介 -->
      <div class="product-intro">
        <h1>产品简介</h1>
        <div class="rows-2">
          <info-item-vue
            title="产品背景"
            desc="医保结算清单是医疗机构与医保部门之间的统一结算凭证，用于规范院内医保费用结算工作制度，有利于院内进行成本管理、临床路径管控，同时也是医保管理和数据分析的主要依据。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M1.88889 7.96777H11.3369C11.8278 7.96777 12.2258 7.56976 12.2258 7.07883V1.88889C12.2258 1.39796 11.8278 1 11.3369 1H1.88889C1.39796 1 1 1.39796 1 1.88889V7.07885C1 7.56976 1.39796 7.96777 1.88889 7.96777ZM24.1111 1H14.6631C14.1722 1 13.7742 1.39796 13.7742 1.88889V7.07885C13.7742 7.56978 14.1722 7.96779 14.6631 7.96779H24.1111C24.602 7.96779 25 7.56978 25 7.07885V1.88889C25 1.39796 24.602 1 24.1111 1ZM24.1111 9.51612H1.88889C1.39796 9.51612 1 9.91408 1 10.405V15.595C1 16.0859 1.39796 16.4839 1.88889 16.4839H24.1111C24.602 16.4839 25 16.0859 25 15.595V10.405C25 9.91408 24.602 9.51612 24.1111 9.51612ZM11.3369 18.0323H1.88889C1.39796 18.0323 1 18.4302 1 18.9211V24.1111C1 24.602 1.39796 25 1.88889 25H11.3369C11.8278 25 12.2258 24.602 12.2258 24.1111V18.9211C12.2258 18.4302 11.8278 18.0323 11.3369 18.0323ZM24.1111 18.0323H14.6631C14.1722 18.0323 13.7742 18.4302 13.7742 18.9212V24.1111C13.7742 24.602 14.1722 25 14.6631 25H24.1111C24.602 25 25 24.602 25 24.1111V18.9211C25 18.4302 24.602 18.0323 24.1111 18.0323Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M24.1111 9.51562H1.88889C1.39796 9.51562 1 9.91359 1 10.4045V15.5945C1 16.0854 1.39796 16.4834 1.88889 16.4834H24.1111C24.602 16.4834 25 16.0854 25 15.5945V10.4045C25 9.91359 24.602 9.51562 24.1111 9.51562Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
          <info-item-vue
            title="产品概述"
            desc="医保结算清单质控系统是针对清单管理的独立系统，用于规范清单填写、减缓“按清单规范填写首页、按首页规范填写清单”的矛盾、提高清单上报质量。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M15.5495 15.2343H4.79366C4.25764 15.264 3.79607 14.862 3.76629 14.3558C3.73651 13.8347 4.1683 13.4029 4.68943 13.388H15.5198C16.0558 13.3582 16.5173 13.7602 16.5471 14.2665C16.5769 14.7876 16.1451 15.2194 15.624 15.2343H15.5495ZM15.5495 19.8798H4.79366C4.25764 19.9095 3.79607 19.5075 3.76629 19.0013C3.73651 18.4802 4.1683 18.0484 4.68943 18.0335H15.5198C16.0558 18.0037 16.5173 18.4057 16.5471 18.9119C16.5769 19.4331 16.1451 19.8649 15.624 19.8798C15.6091 19.8946 15.5793 19.8946 15.5495 19.8798ZM4.31719 5.92839C4.31719 4.90102 5.18078 4.06722 6.26771 4.06722H8.70957C9.7816 4.06722 10.6601 4.90102 10.6601 5.92839V8.25114C10.6601 9.27851 9.79649 10.1123 8.72446 10.1123H6.2826C5.21056 10.1123 4.33208 9.27851 4.33208 8.25114C4.31719 8.25114 4.31719 5.92839 4.31719 5.92839ZM19.4506 22.679V3.32275C19.4506 2.29538 18.0807 1 17.0087 1H2.5C1.42796 1 0.0581384 2.29538 0.0581384 3.32275V22.679C0.0581384 23.7063 1.42796 25.0017 2.5 25.0017H20.4447C20.668 25.0017 20.8467 24.823 20.8616 24.5997C20.8616 24.4657 20.8021 24.3317 20.6829 24.2572C20.0725 23.8106 19.4506 23.215 19.4506 22.679Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M21.0173 8.41878C21.0173 7.78622 21.5301 7.27344 22.1627 7.27344H24.8547C25.4872 7.27344 26 7.78622 26 8.41878V21.8547C26 22.4872 25.4872 23 24.8547 23H22.1627C21.5301 23 21.0173 22.4872 21.0173 21.8547V8.41878Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
        </div>
      </div>
      <!-- 痛点分析 -->
      <div class="pains-analysis">
        <h1>痛点分析</h1>
        <div class="rows-3">
          <pains-analysis-item
            title="医保结算清单质控规则常变且多变，各科医生对于医保知识的掌握不充分、不及时。由于一线医生护士工作对医保结算清单质控规则缺少了解学习的渠道，往往在做出违规医疗行为后才后知后觉。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M32.2242 2.5H5.98488C5.02277 2.5 4.2356 3.28718 4.2356 4.24929V35.7364C4.2356 36.6986 5.02277 37.4857 5.98488 37.4857H22.953C20.1541 35.649 18.2299 32.4128 18.2299 28.7393C18.2299 22.9667 22.953 18.2436 28.7256 18.2436C30.6498 18.2436 32.3991 18.7684 33.9735 19.643V4.24929C33.9735 3.28718 33.1863 2.5 32.2242 2.5ZM19.9792 19.9929H9.48346V16.4943H19.9792V19.9929ZM25.227 12.9957H9.48346V9.49715H25.227V12.9957Z"
                  fill="#929EB0"
                />
                <path
                  d="M31.1149 19.813C31.4196 19.7085 31.7572 19.7849 31.9872 20.0104L36.0727 24.0158C36.5974 24.5406 36.7724 25.2403 36.4225 25.94C36.1601 26.6397 35.5479 26.9896 34.7607 26.9896H23.7706C23.1258 26.9896 22.6032 26.4669 22.6032 25.8221V24.6585C22.6032 24.0137 23.1258 23.491 23.7706 23.491H30.5624L30.5397 20.6252C30.5368 20.2589 30.7684 19.9319 31.1149 19.813ZM23.1279 33.4619L27.2003 37.4545C27.4369 37.6864 27.787 37.7589 28.0961 37.6399C28.4227 37.5143 28.6382 37.2005 28.6382 36.8506V33.9867H35.43C36.0748 33.9867 36.5974 33.464 36.5974 32.8193V31.6556C36.5974 31.0108 36.0748 30.4882 35.43 30.4882H24.3524C23.6838 30.4882 23.095 30.8875 22.7388 31.4572C22.7057 31.5101 22.6789 31.5669 22.6598 31.6264C22.4436 32.303 22.6252 33.043 23.1279 33.4619Z"
                  fill="#50627F"
                />
              </svg>
            </template>
          </pains-analysis-item>
          <pains-analysis-item
            title="医保基金监控不到位，院方医保基金一旦超额，医保中心将不再给予支付，院方将自负盈亏。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M20.4759 2.5C7.65899 2.5 1.25003 5.46503 1.25003 10.0486C1.25003 12.9948 1.21817 13.2698 3.21645 14.3105L4.01045 16.9371H4.30778C6.51456 12.2291 12.5668 10.8514 20 10.8514C27.4332 10.8514 33.9628 12.2311 35.9606 16.9371H35.9896L36.771 14.3993C38.5429 12.6647 38.75 12.8798 38.75 10.0511C38.75 7.96173 37.7345 2.5 20.4759 2.5ZM19.6988 13.7887C11.8312 13.7887 5.77897 14.2996 5.77897 22.5544C5.77897 30.8092 12.1585 37.5 20.0261 37.5C27.8937 37.5 34.2737 30.8097 34.2737 22.5544C34.2737 14.2991 27.5674 13.7887 19.6988 13.7887ZM20.0261 30.5545C16.8554 30.5545 14.2847 27.8581 14.2847 24.5318C14.2847 21.2055 16.8549 18.5096 20.0261 18.5096C23.1973 18.5096 25.767 21.205 25.767 24.5323C25.767 27.8596 23.1968 30.5545 20.0261 30.5545ZM20.0005 22.1168C18.7866 22.1168 17.8019 23.1491 17.8019 24.4228C17.8004 24.825 17.901 25.2208 18.0938 25.571C18.2866 25.9212 18.565 26.2138 18.9014 26.4199C19.2342 26.6222 19.6138 26.7289 20.0005 26.7289C20.3872 26.7289 20.7668 26.6222 21.0995 26.4199C21.4358 26.2138 21.7139 25.9212 21.9066 25.5711C22.0992 25.221 22.1997 24.8254 22.1981 24.4233C22.1981 23.1491 21.2144 22.1168 20.0005 22.1168Z"
                  fill="#929EB0"
                />
                <path
                  d="M20.4759 2.5C7.65899 2.5 1.25003 5.46503 1.25003 10.0486C1.25003 12.9948 1.21817 13.2698 3.21645 14.3105L4.01045 16.9371H4.30778C6.51456 12.2291 12.5668 10.8514 20 10.8514C27.4332 10.8514 33.9628 12.2311 35.9606 16.9371H35.9896L36.771 14.3993C38.5429 12.6647 38.75 12.8798 38.75 10.0511C38.75 7.96173 37.7345 2.5 20.4759 2.5Z"
                  fill="#50627F"
                />
              </svg>
            </template>
          </pains-analysis-item>
          <pains-analysis-item
            title="信息体系统未对接，人工审核机制落后，由于医院业务量巨大，医疗项目繁多，医保办人工审核的模式已经不能满足当前医保结算清单的审核。"
          >
            <template #icon>
              <img
                :src="icon01"
                alt=""
                srcset=""
                style="width: 40px; height: 40px"
              />
            </template>
          </pains-analysis-item>
        </div>
      </div>

      <!-- 产品特色 -->
      <div class="product-features">
        <h1>解决方案</h1>
        <div class="section">
          <h1>质控规则体系</h1>
          <img src="../../assets/medical/solution0201.png" alt="" srcset="" />
        </div>
        <div class="section">
          <h1>清单流程管理</h1>
          <img src="../../assets/medical/solution0202.png" alt="" srcset="" />
        </div>
      </div>
      <!-- 产品价值 -->
      <div class="value">
        <h1>产品价值</h1>
        <div class="rows-3">
          <value-item-vue
            title="全面识别违规风险"
            desc="以数字化信息技术手段，多维度、多指标灵活监控医保结算清单的数据质量，帮助院方及时有效识别潜在问题数据。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M33.7531 49.9761V15.7298L36.6531 13.8553C36.9322 13.6553 37.2668 13.5475 37.6101 13.5469H49.4421C50.1539 13.5721 50.8281 13.8726 51.3228 14.385C51.8175 14.8974 52.0941 15.5817 52.0943 16.294V46.1745C52.0571 46.8296 51.7869 47.4499 51.3324 47.9232C50.878 48.3966 50.2692 48.6918 49.6161 48.7555H37.995L33.4842 51.3602C33.079 51.5528 32.6327 51.6434 32.1845 51.6239C32.6076 51.6035 33.0067 51.421 33.2988 51.1142C33.5909 50.8073 33.7536 50.3998 33.7531 49.9761ZM30.2467 49.9761V15.7298L27.3467 13.8553C27.0677 13.6553 26.7331 13.5475 26.3897 13.5469H14.5472C13.8354 13.5721 13.1612 13.8726 12.6665 14.385C12.1718 14.8974 11.8953 15.5817 11.895 16.294V46.1745C11.9322 46.8296 12.2024 47.4499 12.6569 47.9232C13.1114 48.3966 13.7201 48.6918 14.3732 48.7555H25.9996L30.5104 51.3602C30.9156 51.5528 31.3619 51.6434 31.8101 51.6239C31.3881 51.6016 30.9907 51.4183 30.6999 51.1117C30.409 50.8052 30.2468 50.3987 30.2467 49.9761Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M56.8583 7.14173H47.6055C46.5378 7.14173 45.6722 6.27614 45.6722 5.20839V4.93333C45.6722 3.86558 46.5378 3 47.6055 3H59.0667C60.1344 3 61 3.86558 61 4.93333V16.3945C61 17.4622 60.1344 18.3278 59.0667 18.3278H58.7916C57.7239 18.3278 56.8583 17.4622 56.8583 16.3945V7.14173ZM56.8583 56.8556V47.6055C56.8583 46.5378 57.7239 45.6722 58.7916 45.6722H59.0667C60.1344 45.6722 61 46.5378 61 47.6055V59.0667C61 60.1344 60.1344 61 59.0667 61H47.6055C46.5378 61 45.6722 60.1344 45.6722 59.0667V58.7912C45.6722 57.7236 46.5375 56.8581 47.6051 56.8578L56.8583 56.8556ZM7.14436 56.8556H16.3971C17.4649 56.8556 18.3305 57.7212 18.3305 58.789V59.0667C18.3305 60.1344 17.4649 61 16.3971 61H4.93333C3.86558 61 3 60.1344 3 59.0667V47.6055C3 46.5378 3.86558 45.6722 4.93333 45.6722H5.20885C6.27642 45.6722 7.14193 46.5375 7.14218 47.6051L7.14436 56.8556ZM7.14436 7.14173V16.3945C7.14436 17.4622 6.27878 18.3278 5.21103 18.3278H4.93333C3.86558 18.3278 3 17.4622 3 16.3945V4.93333C3 3.86558 3.86558 3 4.93333 3H16.3945C17.4622 3 18.3278 3.86558 18.3278 4.93333V5.2084C18.3278 6.27615 17.4622 7.14173 16.3945 7.14173H7.14436Z"
                  fill="#386FC6"
                />
                <path
                  d="M26.7932 23.6646C26.7932 23.2003 26.6088 22.7551 26.2805 22.4268C25.9522 22.0985 25.5069 21.9141 25.0426 21.9141H21.2779C20.8133 21.9141 20.3677 22.0986 20.0392 22.4272C19.7106 22.7557 19.5261 23.2013 19.5261 23.6659C19.5261 24.1305 19.7106 24.5761 20.0392 24.9047C20.3677 25.2332 20.8133 25.4178 21.2779 25.4178H25.0426C25.5069 25.4164 25.9518 25.2312 26.2799 24.9026C26.6079 24.574 26.7925 24.1289 26.7932 23.6646ZM26.7932 31.4814C26.7932 31.0172 26.6088 30.5719 26.2805 30.2436C25.9522 29.9153 25.5069 29.7309 25.0426 29.7309H20.4237C19.9591 29.7309 19.5135 29.9155 19.185 30.244C18.8564 30.5725 18.6719 31.0181 18.6719 31.4827C18.6719 31.9474 18.8564 32.393 19.185 32.7215C19.5135 33.05 19.9591 33.2346 20.4237 33.2346H25.0426C25.5065 33.2332 25.9509 33.0483 26.2789 32.7203C26.6069 32.3924 26.7918 31.9479 26.7932 31.4841V31.4814ZM26.7932 39.2982C26.7932 38.834 26.6088 38.3887 26.2805 38.0604C25.9522 37.7321 25.5069 37.5477 25.0426 37.5477H21.705C21.2404 37.5477 20.7948 37.7323 20.4663 38.0608C20.1377 38.3893 19.9531 38.8349 19.9531 39.2996C19.9531 39.7642 20.1377 40.2098 20.4663 40.5383C20.7948 40.8669 21.2404 41.0514 21.705 41.0514H25.04C25.5045 41.0514 25.9501 40.8671 26.2788 40.5389C26.6075 40.2106 26.7925 39.7654 26.7932 39.3009V39.2982Z"
                  fill="white"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="提高人员工作效率"
            desc="加强院方医保处与具体科室的线上沟通，双方可对疑似违规行为进行处理，过程全程记录，省时、省力。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M57.5427 4.2832H40.6631C38.3598 4.2832 37.3421 7.09535 38.8754 8.62864L43.7364 13.4896C34.2688 24.2294 20.717 32.4181 6.39512 32.4181C4.86183 32.4181 3.8374 33.4425 3.8374 34.9758C3.8374 36.5091 4.86183 37.5335 6.39512 37.5335C23.5291 37.5335 39.3843 32.6725 50.894 20.6539L55.5005 25.2605C57.0338 26.7938 59.846 25.7693 59.846 23.4727V6.84092C60.1004 5.30763 59.076 4.2832 57.5427 4.2832Z"
                  fill="#386FC6"
                />
                <path
                  d="M7.05798 47.5359H15.7957C17.945 47.5359 19.0163 48.6072 19.0163 50.7565V55.3162C19.0163 57.4655 17.945 58.5368 15.7957 58.5368H7.05798C4.9087 58.5368 3.8374 57.4655 3.8374 55.3162V50.7565C3.8374 48.6072 4.91539 47.5359 7.05798 47.5359ZM27.5532 42.9829H36.2909C38.4402 42.9829 39.5115 44.0542 39.5115 46.2035V55.3229C39.5115 57.4722 38.4402 58.5435 36.2909 58.5435H27.5532C25.4039 58.5435 24.3326 57.4722 24.3326 55.3229V46.2035C24.3326 44.0542 25.4039 42.9829 27.5532 42.9829ZM48.0417 31.5938H56.7794C58.9287 31.5938 60 32.665 60 34.8143V55.3162C60 57.4655 58.9287 58.5368 56.7794 58.5368H48.0417C45.8924 58.5368 44.8211 57.4655 44.8211 55.3162V34.821C44.8211 32.6717 45.8991 31.5938 48.0417 31.5938Z"
                  fill="#1CB3B8"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="提高医保管理水平"
            desc="借助数字化系统，提高我院医保办管理水平，数据报表、统计分析，辅助查漏补缺，精准定位，预防风险。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M8 56C5.6 56 4 54.4 4 52V24H60V52C60 54.4 58.4 56 56 56H8ZM16 36H13C11.8954 36 11 36.8954 11 38C11 39.1046 11.8954 40 13 40H16V43C16 44.1046 16.8954 45 18 45C19.1046 45 20 44.1046 20 43V40H23C24.1046 40 25 39.1046 25 38C25 36.8954 24.1046 36 23 36H20V33C20 31.8954 19.1046 31 18 31C16.8954 31 16 31.8954 16 33V36Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M8 8H56C58.4 8 60 9.6 60 12V20H4V12C4 9.6 5.6 8 8 8Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </value-item-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBg from "../common/TopBg.vue";
import PainsAnalysisItem from "../common/pains-analysis-item.vue";
import infoItemVue from "../common/info-item.vue";
import valueItemVue from "../common/value-item.vue";

export default {
  name: "MedicalControl",
  components: {
    TopBg,
    PainsAnalysisItem,
    infoItemVue,
    valueItemVue,
  },
  data() {
    return {
      topBg: require("../../assets/medical/bg02.png"),
      icon01: require("../../assets/medical/icon01.png"),
    };
  },
};
</script>

<style scoped>
.medical-detail-cntainer {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
}

h1 {
  color: #1f2329;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
}

.product-features .section {
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  padding: 32px;
  margin-bottom: 32px;
}

.product-features .section img {
  width: 100%;
}

.product-features .section:last-child {
  margin-bottom: 80px;
}

.product-features .section h1 {
  font-size: 32px;
}

.value .rows-3 {
  padding-bottom: 16px;
  margin-bottom: 0px;
}
</style>

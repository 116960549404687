<template>
  <div class="about">
    <div class="about-content main-content">
      <h2 class="section-title">关于我们</h2>
      <div class="about-cards">
        <div class="about-card digital-solution">
          <div class="card-content">
            <h3>数字化解决方案商</h3>
            <p>
              智先生科技秉承“让数字化永远在线”的使命，专注数字化领域产品的研发，致力于构建全新一代智能运维—“智维”、智慧医疗—“医智信”、数据决策—“医维智友”三大数字业务品牌，全力推进行业数字化建设。
            </p>
            <p>
              目前已申请国家发明专利、软件著作权、产品认证等100余项，为400多家行业客户提供产品、服务与解决方案。
            </p>
            <router-link to="/about" class="link-more">了解更多</router-link>
          </div>
        </div>
        <div class="about-right">
          <div class="about-card culture">
            <div class="card-content">
              <h3>企业文化</h3>
              <p>客户第一、团队合作、诚信、敬业、激情、创新</p>
            </div>
          </div>
          <div class="about-card advantage">
            <div class="card-content">
              <h3>优势介绍</h3>
              <p>
                国家高新技术企业、江苏省专精特新中小企业、江苏省科技型中小企业、江苏省民营科技企业、江苏省软件核心竞争力企业(创新型)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<style scoped>
.about {
  padding: 0 0;
  margin-bottom: -60px;
  background-color: #fff;
}

.about-content {
  transform: translateY(-126px);
}

.section-title {
  color: #1f2329;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: left;
}

.about-cards {
  display: flex;
  gap: 16px;
}

.about-card {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-size: cover;
  background-position: center;
  position: relative;
}

.digital-solution {
  flex: 1;
  background: url("../../../assets/home/about_bg_01.png"),
    linear-gradient(
        180deg,
        rgba(28, 179, 184, 0.9) 0%,
        rgba(28, 179, 184, 0.9) 100%
      )
      #386fc6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.about-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.culture {
  background: url("../../../assets/home/about_bg_02.png"),
    linear-gradient(
      95deg,
      rgba(56, 111, 198, 0.15) 0.17%,
      rgba(28, 179, 184, 0.1) 99.83%
    );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 192px;
}

.advantage {
  background: url("../../../assets/home/about_bg_03.png"),
    linear-gradient(
      95deg,
      rgba(56, 111, 198, 0.15) 0.17%,
      rgba(28, 179, 184, 0.1) 99.83%
    );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 192px;
}

.card-content {
  padding: 32px;
  height: 100%;
}

.card-content h3 {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 18px;
}

.about-right .card-content h3 {
  color: #1f2329;
  font-size: 24px;
}

.card-content p {
  color: #fff;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 16px;
}

.about-right .card-content p {
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.link-more {
  width: 176px;
  height: 48px;
  display: block;
  flex-shrink: 0;
  line-height: 48px;
  text-align: left;
  padding: 0 16px;
  color: #1f2329;
  font-size: 16px;
  font-weight: 500;
  margin-top: 58px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 32px;
  bottom: 32px;
}

.link-more::after {
  content: "→";
  position: absolute;
  right: 16px;
  top: 50%;
  color: #1f2329;
  transform: translateY(-50%);
  font-size: 16px;
}

.link-more:hover {
  transform: scale(1.01);
  border: 1px solid #fff;
  color: #1cb3b8;
  background: #fff;
}

.link-more:hover::after {
  color: #1cb3b8;
}
</style>
<template>
  <div class="button-container" :style="{ width: width }">
    <a
      ref="button"
      :style="{ height: height }"
      @mouseenter="mouseenter"
      @mousemove="mousemove"
      @mouseleave="mouseleave"
      @click="goto(href)"
      >{{ text }}</a
    >
    <div ref="diffusedBlock" class="diffused-block"></div>
  </div>
</template>

<script>
export default {
  props: {
    href: String,
    text: String,
    width: {
      type: String,
      default: "288px",
    },
    height: {
      type: String,
      default: "48px",
    },
  },
  methods: {
    mouseenter() {
      this.$refs.diffusedBlock.style.opacity = 1;
    },
    mousemove(e) {
      const button = this.$refs.button;
      const diffusedBlock = this.$refs.diffusedBlock;
      const rect = button.getBoundingClientRect();
      const x = e.clientX - rect.left - diffusedBlock.offsetWidth / 2;

      // 确保色块在按钮内
      const minX = 0;
      const maxX = rect.width - diffusedBlock.offsetWidth;

      const clampedX = Math.max(minX, Math.min(x, maxX));

      diffusedBlock.style.transform = `translate(${clampedX}px, ${10}px)`;
    },
    mouseleave() {
      this.$refs.diffusedBlock.style.opacity = 0;
    },
    goto(href) {
      if (href) {
        this.$router.push(href);
      }
    },
  },
};
</script>

<style scoped>
.button-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 8px;
}

a {
  display: block;
  flex-shrink: 0;
  border-radius: 8px;
  background: #1cb3b8;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  line-height: 48px;
  cursor: pointer;
}

a:hover {
  transform: scale(1.01);
}

.diffused-block {
  position: absolute;
  width: 80px;
  height: 80px;
  background: radial-gradient(
    circle,
    rgba(18, 96, 219, 1) 0%,
    rgba(18, 96, 219, 1) 50%
  );
  border-radius: 50%;
  filter: blur(20px);
  pointer-events: none;
  transition: opacity 0.3s ease;
  top: -24px;
  left: 0;
  opacity: 0;
}
</style>

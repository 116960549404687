<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <div class="footer-form">
    <div class="main-content">
      <h1>在这里快速提交您的需求信息</h1>
      <div class="rows-4">
        <form-input :data.sync="form.name" label="姓名"></form-input>
        <form-input :data.sync="form.companyName" label="单位"></form-input>
        <form-input :data.sync="form.phone" label="电话 "></form-input>
        <form-input
          v-if="isMobile"
          :data.sync="form.subMessage"
          label="需求"
          type="area"
        ></form-input>
        <div @click="submit">
          <read-more class="read-more" width="100%" text="提交信息"></read-more>
        </div>
      </div>
      <form-input
        v-if="!isMobile"
        :data.sync="form.subMessage"
        label="需求"
        type="area"
        style="width: 74.8%"
      ></form-input>
    </div>
  </div>
</template>

<script>
import FormInput from "./common/form-input.vue";
import { addDemand } from "@/api";
export default {
  components: {
    FormInput,
  },
  data() {
    return {
      form: {
        name: undefined,
        companyName: undefined,
        phone: undefined,
        subMessage: undefined,
      },
      isMobile: false,
    };
  },
  mounted() {
    // 判断是否为移动设备
    const isMobile = window.innerWidth < 1300;
    this.isMobile = isMobile;
  },
  methods: {
    submit() {
      if (!this.form.name || !this.form.phone) {
        this.$message({
          message: "请填姓名和电话！",
          type: "warning",
        });
        return;
      }
      addDemand(this.form).then(() => {
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.form = {
          name: undefined,
          companyName: undefined,
          phone: undefined,
          subMessage: undefined,
        };
      });
    },
  },
};
</script>

<style scoped>
.footer-form {
  background-color: #f6f8fd;
  padding-bottom: 64px;
  padding-top: 64px;
}
h1 {
  color: #1f2329;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 22px;
  text-align: left;
}

.footer-form .rows-4 {
  margin-bottom: 16px;
}

.footer-form .rows-4 button {
  border-radius: 8px;
  background: #1cb3b8;
  border: none;
  font-size: 16px;
  text-align: center;
  line-height: 44px;
  font-weight: 500;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.footer-form .rows-4 button:hover {
  transform: scale(1.01);
}
</style>
<template>
  <div id="app">
    <Header v-if="!isMobile" />
    <HeaderMobile v-if="isMobile" />
    <router-view />
    <FooterForm v-if="$route.path != '/free'" />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import HeaderMobile from "./components/HeaderMobile.vue";
import Footer from "./components/Footer.vue";
import FooterForm from "./components/footer-form.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    FooterForm,
    HeaderMobile,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    // 判断是否为移动设备
    const isMobile = window.innerWidth < 1300;
    this.isMobile = isMobile;
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.fullPath != oldValue?.fullPath) {
          window.scrollTo(0, 0);
        }
      },
    },
  },
};
</script>

<style>
#app {
  font-family: "PingFang SC", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
}
.main-content {
  width: 1328px;
  margin: 0 auto;
}

.rows-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 80px;
}

.rows-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 80px;
}

.rows-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 80px;
}

.rows-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-bottom: 80px;
}
</style>

<template>
  <div class="medical">
    <div class="medical-content main-content">
      <h2 class="section-title">医智信</h2>
      <div class="medical-cards">
        <div
          class="medical-card"
          v-for="(item, index) in medicalItems"
          :key="index"
          :class="{ expanded: expandedIndex === index }"
          @mouseenter="handleMouseEnter(index)"
        >
          <div class="card-content">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
          <div class="card-footer">
            <green-button
              :href="item.link"
              class="btn-more"
              width="100%"
              text="了解更多"
            ></green-button>
            <router-link class="btn-chat" to="/free">在线留言</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Medical",
  data() {
    return {
      expandedIndex: 0,
      medicalItems: [
        {
          title: "医保基金智能监管系统",
          description:
            "迎合国家基金监管政策，借助智能化监管手段，助力医院降低医保基金违规风险，减轻违规罚款压力，共同打造高效、合规的医保管理体系。",
          link: "/medical",
        },
        {
          title: "医保结算清单系统",
          description:
            "规范清单填写，减缓按清单规范填写首页、按首页规范填写清单的矛盾，提高医保结算效率。",
          link: "/medical-control",
        },
        {
          title: "DRG/DIP院内综合管理平台",
          description:
            "帮助医院提高编码水平和病案质量、规范诊疗行为、加强成本管理、促进绩效评价，快速适应新的支付方式改革，助力医院高质量发展。",
          link: "/drg-platform",
        },
        {
          title: "互联网医院",
          description:
            "向患者提供包括问诊、开方以及续方的一系列便捷高效的医疗服务，拓展医疗服务空间和内容，进行随访管理和远程指导。",
          link: "/internet-hospital",
        },
      ],
    };
  },
  mounted() {
    // 判断是否为移动设备
    const isMobile = window.innerWidth < 1300;
    this.isMobile = isMobile;
    // 如果是移动设备，将所有卡片设置为展开状态
    if (this.isMobile) {
      this.expandedIndex = -1; // -1表示全部展开
      this.medicalItems.forEach((_, index) => {
        this.$nextTick(() => {
          const card = document.querySelector(
            `.medical-card:nth-child(${index + 1})`
          );
          if (card) {
            card.classList.add("expanded");
          }
        });
      });
    }
  },
  methods: {
    handleMouseEnter(index) {
      this.expandedIndex = index;
    },
  },
};
</script>

<style scoped>
.medical {
  width: 100%;
  background-color: #fff;
  padding: 64px 0;
}

.section-title {
  color: #1f2329;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: left;
}

.medical-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.medical-card {
  transition: all 0.5s ease;
  padding: 32px 16px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 208px;
  border-radius: 8px;
}

.medical-card.expanded {
  width: 656px;
  padding: 32px;
}

.medical-card:nth-child(1) {
  background: url("../../../assets/home/me_small_01.png"),
    linear-gradient(8deg, rgba(56, 111, 198, 0.1) 0%, #386fc6 62.49%);
  background-size: cover;
  background-position: center;
}

.medical-card:nth-child(2) {
  background: url("../../../assets/home/me_small_02.png"),
    linear-gradient(8deg, rgba(28, 179, 184, 0.1) 0%, #1cb3b8 59.02%);
  background-size: cover;
  background-position: center;
}

.medical-card:nth-child(3) {
  background: url("../../../assets/home/me_small_03.png"),
    linear-gradient(8deg, rgba(59, 183, 158, 0.1) 0%, #3bb79e 62.49%);
  background-size: cover;
  background-position: center;
}

.medical-card:nth-child(4) {
  background: url("../../../assets/home/me_small_04.png"),
    linear-gradient(8deg, rgba(87, 102, 151, 0.1) 0%, #576697 65.12%);
  background-size: cover;
  background-position: center;
}

.medical-card.expanded:nth-child(1) {
  background: url("../../../assets/home/me_big_01.png"),
    linear-gradient(8deg, #f8faff 0%, #d1d9ec 100%);
  background-size: cover;
  background-position: center;
}

.medical-card.expanded:nth-child(2) {
  background: url("../../../assets/home/me_big_02.png"),
    linear-gradient(8deg, #f8faff 0%, #d1d9ec 100%);
  background-size: cover;
  background-position: center;
}

.medical-card.expanded:nth-child(3) {
  background: url("../../../assets/home/me_big_03.png"),
    linear-gradient(8deg, #f8faff 0%, #d1d9ec 100%);
  background-size: cover;
  background-position: center;
}

.medical-card.expanded:nth-child(4) {
  background: url("../../../assets/home/me_big_04.png"),
    linear-gradient(8deg, #f8faff 0%, #d1d9ec 100%);
  background-size: cover;
  background-position: center;
}

.card-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.card-content h3 {
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.medical-card.expanded .card-content h3 {
  color: #1f2329;
}

.card-content p {
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.medical-card.expanded .card-content p {
  color: #50627f;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
}

.medical-card.expanded .card-footer {
  visibility: visible;
}

.card-footer .btn-more {
  flex: 1;
  margin-right: 16px;
}

.card-footer .btn-chat {
  border-radius: 8px;
  background: #fff;
  height: 48px;
  flex: 1;
  color: #1f2329;
  line-height: 48px;
  padding-left: 24px;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.card-footer .btn-chat:hover {
  color: #1cb3b8;
  transform: scale(1.01);
}

.card-footer .btn-chat:active {
  background: rgba(56, 111, 198, 0.1);
}

.card-footer .btn-chat::after {
  content: "→";
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
</style>
<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <div class="join-cntainer">
    <TopBg title="加入我们" sub-title="星河万里，我“智”要你！" :bg="topBg" />
    <div class="main-content">
      <div class="company">
        <h1>公司团队</h1>
        <div class="info-section">
          <div class="info">
            公司自创立以来，培养了一支具有丰富经验的专职研发团队。两年来，公司主导产品与服务已经在<span>400</span>多家医院、高校、政企等单位应用，服务质量、团队技术获得广大用户的高度评价。
          </div>
          <div class="img">
            <img src="../assets/join/chart.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div class="feature-section">
        <h1>智等你来</h1>
        <div class="info">
          <p>带薪年假、团建、生日祝福、定期体检等，更多福利等你来呦！</p>
          <div class="control">
            <div
              class="arrow-left"
              :class="{ 'active-arrow': activeFeature == 0 ? false : true }"
              @click="changeFeature(0)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  :stroke="activeFeature == 0 ? '#929EB0' : '#fff'"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="arrow-right"
              :class="{
                'active-arrow':
                  activeFeature >= features.length - 1 ? false : true,
              }"
              @click="changeFeature(activeFeature + 1)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 5L12.5 10L7.5 15"
                  :stroke="
                    activeFeature >= features.length - 1 ? '#929EB0' : '#fff'
                  "
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <el-carousel
          ref="joinCarousel"
          height="300px"
          :autoplay="false"
          arrow="never"
          indicator-position="none"
          :initial-index="activeFeature"
        >
          <el-carousel-item v-for="(item, index) in features" :key="index">
            <div class="features">
              <div
                v-for="(feature, index) in item"
                :key="'feature' + index"
                class="feature-item"
                :style="{ 'background-image': 'url(' + feature.bg + ')' }"
              >
                <div class="feature-content">
                  <h3>{{ feature.title }}</h3>
                  <p>{{ feature.info }}</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="offer">
        <h1>令人心动的offer</h1>
        <div class="offer-content">
          <div class="job-list">
            <div v-for="(job, index) in jobs" :key="index" class="job-item">
              <div class="job-header" @click="toggleJob(index)">
                <div class="job-title">
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M16 18C18.6 18 21.2 18.3633 23.8 19.0893C25.0082 19.4266 26.0728 20.1501 26.8311 21.1492C27.5895 22.1484 28.0001 23.3683 28 24.6227V26C28 26.7072 27.719 27.3855 27.219 27.8856C26.7189 28.3857 26.0406 28.6667 25.3333 28.6667H6.66667C5.95942 28.6667 5.28115 28.3857 4.78105 27.8856C4.28095 27.3855 4 26.7072 4 26V24.622C3.99995 23.3676 4.41047 22.1477 5.16886 21.1486C5.92725 20.1494 6.99182 19.426 8.2 19.0887C10.8 18.364 13.4 18 16 18ZM15.998 19.3333C15.3653 19.3333 15.0387 19.5553 15.018 20L14.0667 24.9387C14.0462 25.0443 14.0517 25.1534 14.0826 25.2565C14.1135 25.3595 14.1689 25.4536 14.244 25.5307L15.5233 26.844C15.5854 26.9077 15.6597 26.9583 15.7417 26.9929C15.8236 27.0275 15.9117 27.0453 16.0007 27.0453C16.0896 27.0453 16.1777 27.0275 16.2597 26.9929C16.3417 26.9583 16.4159 26.9077 16.478 26.844L17.758 25.5307C17.833 25.4536 17.8883 25.3595 17.919 25.2564C17.9498 25.1533 17.9552 25.0443 17.9347 24.9387L16.9813 20C16.9587 19.5553 16.6307 19.3333 15.998 19.3333ZM16 4C19.682 4 22.6667 6.98467 22.6667 10.6667C22.6667 14.3487 19.682 17.3333 16 17.3333C12.318 17.3333 9.33333 14.3487 9.33333 10.6667C9.33333 6.98467 12.318 4 16 4Z"
                        fill="#1CB3B8"
                      />
                      <path
                        d="M16 4C19.682 4 22.6667 6.98467 22.6667 10.6667C22.6667 14.3487 19.682 17.3333 16 17.3333C12.318 17.3333 9.33334 14.3487 9.33334 10.6667C9.33334 6.98467 12.318 4 16 4Z"
                        fill="#386FC6"
                      /></svg
                    >{{ job.jobName }}
                  </h3>
                  <div class="job-tags">
                    <span class="tag"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M3.28587 11.4219C2.07945 11.7838 1.33325 12.2838 1.33325 12.8361C1.33325 13.9406 4.31802 14.8361 7.99992 14.8361C11.6818 14.8361 14.6666 13.9406 14.6666 12.8361C14.6666 12.2838 13.9204 11.7838 12.714 11.4219"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.00008 12.1693C8.00008 12.1693 12.3334 9.33727 12.3334 6.0632C12.3334 3.72855 10.3933 1.83594 8.00008 1.83594C5.60685 1.83594 3.66675 3.72855 3.66675 6.0632C3.66675 9.33727 8.00008 12.1693 8.00008 12.1693Z"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.00001 7.83333C8.92048 7.83333 9.66668 7.08713 9.66668 6.16667C9.66668 5.2462 8.92048 4.5 8.00001 4.5C7.07954 4.5 6.33334 5.2462 6.33334 6.16667C6.33334 7.08713 7.07954 7.83333 8.00001 7.83333Z"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linejoin="round"
                        /></svg
                      >{{ job.workplace }}</span
                    >
                    <span class="tag"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M1.66675 6.83594H14.3334V13.8359C14.3334 14.2041 14.0349 14.5026 13.6667 14.5026H2.33341C1.96522 14.5026 1.66675 14.2041 1.66675 13.8359V6.83594Z"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.66675 3.5026C1.66675 3.13441 1.96522 2.83594 2.33341 2.83594H13.6667C14.0349 2.83594 14.3334 3.13441 14.3334 3.5026V6.83594H1.66675V3.5026Z"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.33325 1.83594V4.5026"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.6667 1.83594V4.5026"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.33325 11.8359H11.3333"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.66675 11.8359H6.66675"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.33325 9.16406H11.3333"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.66675 9.16406H6.66675"
                          stroke="#50627F"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /></svg
                      >{{ job.createTime.substr(0, 10) }}</span
                    >
                  </div>
                </div>
                <div
                  class="job-arrow"
                  :class="{ 'arrow-up': activeJobs.includes(index) }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#929EB0"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="job-content" v-show="activeJobs.includes(index)">
                <div class="job-section">
                  <h4>岗位职责</h4>
                  <div v-html="job.jobObligation"></div>
                </div>
                <div class="job-section">
                  <h4>任职要求</h4>
                  <div v-html="job.jobRequire"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopBg from "./common/TopBg.vue";
import { getJobs } from "../api/jobs";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Join",
  components: {
    TopBg,
  },
  data() {
    return {
      topBg: require("../assets/joinBg.png"),
      features: [
        [
          {
            title: "带薪年假",
            bg: require("../assets/join/feature01.png"),
            info: "带薪年假是公司对员工的人性化关怀，让员工在辛勤工作之余，能够有时间放松身心，陪伴家人，享受生活。",
          },
          {
            title: "团建",
            bg: require("../assets/join/feature02.png"),
            info: "定期团建是释放压力的“出口”，在忙碌的工作之余，为员工提供了一个放松身心的平台，让大家暂时远离工作的紧张氛围，享受团队的乐趣，提升员工的满意度和幸福感。",
          },
          {
            title: "生日祝福",
            bg: require("../assets/join/feature03.png"),
            info: "公司不仅仅关注工作成果，更在乎员工的个人生活和情感需求。公司送上的温暖关怀，让员工在特殊的日子感受到公司的陪伴和重视。",
          },
        ],
        [
          {
            title: "节日祝福",
            bg: require("../assets/join/feature04.png"),
            info: "在每个特殊时刻公司向员工传递温暖与关怀，增强员工归属感、提升幸福感，从而营造积极和谐的氛围。",
          },
          {
            title: "定期体检",
            bg: require("../assets/join/feature05.png"),
            info: "定期体检是公司对员工健康关怀的重要举措，体现了公司“以人为本”的管理理念。公司不仅关注员工的工作表现，更关心员工的身体健康。传递着“员工健康是公司发展基石”的价值观。",
          },
        ],
      ],
      activeFeature: 0,
      jobs: [],
      activeJobs: [],
    };
  },
  created() {
    this.fetchJobs();
  },
  mounted() {
    // 判断是否为移动设备
    const isMobile = window.innerWidth < 1300;
    this.isMobile = isMobile;
    // 如果是移动设备，将所有卡片设置为展开状态
    if (this.isMobile) {
      this.features = [
        [
          {
            title: "带薪年假",
            bg: require("../assets/join/feature01.png"),
            info: "带薪年假是公司对员工的人性化关怀，让员工在辛勤工作之余，能够有时间放松身心，陪伴家人，享受生活。",
          },
        ],
        [
          {
            title: "团建",
            bg: require("../assets/join/feature02.png"),
            info: "定期团建是释放压力的“出口”，在忙碌的工作之余，为员工提供了一个放松身心的平台，让大家暂时远离工作的紧张氛围，享受团队的乐趣，提升员工的满意度和幸福感。",
          },
        ],
        [
          {
            title: "生日祝福",
            bg: require("../assets/join/feature03.png"),
            info: "公司不仅仅关注工作成果，更在乎员工的个人生活和情感需求。公司送上的温暖关怀，让员工在特殊的日子感受到公司的陪伴和重视。",
          },
        ],
        [
          {
            title: "节日祝福",
            bg: require("../assets/join/feature04.png"),
            info: "在每个特殊时刻公司向员工传递温暖与关怀，增强员工归属感、提升幸福感，从而营造积极和谐的氛围。",
          },
        ],
        [
          {
            title: "定期体检",
            bg: require("../assets/join/feature05.png"),
            info: "定期体检是公司对员工健康关怀的重要举措，体现了公司“以人为本”的管理理念。公司不仅关注员工的工作表现，更关心员工的身体健康。传递着“员工健康是公司发展基石”的价值观。",
          },
        ],
      ];
    }
  },
  methods: {
    changeFeature(index) {
      index = parseInt(index);

      if (index > this.features.length - 1) {
        return;
      }
      this.activeFeature = index;
      this.$refs.joinCarousel.setActiveItem(index);
    },
    async fetchJobs() {
      try {
        const response = await getJobs();
        this.jobs = response.rows;
      } catch (error) {
        console.error("获取职位列表失败:", error);
      }
    },
    toggleJob(index) {
      const jobIndex = this.activeJobs.indexOf(index);
      if (jobIndex === -1) {
        // 如果当前职位未展开，则添加到展开列表中
        this.activeJobs.push(index);
      } else {
        // 如果当前职位已展开，则从展开列表中移除
        this.activeJobs.splice(jobIndex, 1);
      }
    },
  },
};
</script>

<style scoped>
.join-cntainer {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
  padding-bottom: 16px;
}

h1 {
  color: #1f2329;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
}

.company {
  margin-bottom: 80px;
}
.info-section {
  display: flex;
  align-items: center;
}

.info {
  color: #50627f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  text-align: left;
  flex: 1;
  padding-right: 275px;
  box-sizing: border-box;
}

.info span {
  color: #1cb3b8;
  font-weight: 600;
}
.info-section .img {
  flex: 1;
}
.info-section img {
  width: 544px;
  margin: 0 auto;
}

.feature-section {
  margin-bottom: 80px;
  position: relative;
}
.feature-section h1 {
  margin-bottom: 12px;
}
.feature-section .info {
  margin-bottom: 32px;
  color: #50627f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-section .info p {
  flex: 1;
}

.features {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-item {
  width: 432px;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 16px;
}

.features .feature-item:last-child {
  margin-right: 0;
}

.feature-item .feature-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  transition: all 0.2s ease-in-out;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 82px;
  padding: 32px 16px 16px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid #d9dbdf;
  border-bottom: 1px solid #d9dbdf;
  border-left: 1px solid #d9dbdf;
  background: linear-gradient(180deg, rgba(9, 20, 47, 0) 0%, #09142f 100%);
}

.feature-item:hover .feature-content {
  height: 100%;
}

.feature-item .feature-content h3 {
  color: #fff;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 36px;
}

.feature-item .feature-content p {
  color: rgba(255, 255, 255, 0.9);
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
  height: auto;
  display: none;
  transition: all 0.3s ease-in-out;
}

.feature-item:hover .feature-content p {
  display: block;
}

.feature-section .control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}
.feature-section .control .arrow-left,
.feature-section .control .arrow-right {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #d9dbdf;
  background: #fff;
  width: 40px;
  height: 40px;
  margin-left: 8px;
  padding: 10px;
  cursor: pointer;
}

.feature-section .control .arrow-left.active-arrow:hover,
.feature-section .control .arrow-right.active-arrow:hover {
  transform: scale(1.1);
}

.feature-section .control .active-arrow {
  background: #1cb3b8;
}

.arrow-left .svg,
.arrow-right.svg {
  width: 20px;
  height: 20px;
}

.job-item {
  overflow: hidden;
  padding: 24px 0;
  border-bottom: 1px solid #d9dbdf;
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.job-title h3 {
  color: #1f2329;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: left;
  display: flex;
  align-items: center;
}

.job-item:hover .job-title h3 {
  color: #1cb3b8;
}

.job-title h3 svg {
  margin-right: 8px;
}

.job-tags {
  display: flex;
  gap: 32px;
}

.tag {
  border-radius: 4px;
  color: #50627f;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.tag svg {
  margin-right: 8px;
}

.job-arrow {
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(180deg);
}

.job-section {
  margin-top: 16px;
  padding: 16px 18px;
  border-radius: 8px;
  background: #eef1f8;
}

.job-section h4 {
  color: #1f2329;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: left;
}

.job-content div {
  text-align: left;
}
</style>
<style>
.job-section p {
  color: #50627f;
  font-size: 16px;
  line-height: 170%;
  margin-bottom: 12px;
  text-align: left;
}

.job-section p:last-child {
  margin-bottom: 0;
}
</style>


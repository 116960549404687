<template>
  <div class="partner-rows">
    <div class="partner-row">
      <div class="partner-scroll" :class="`scroll-0`">
        <div class="partner-item" v-for="i in partners1" :key="i.id">
          <el-image
            :src="i.casePh"
            fit="contain"
            class="partner-logo"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="partner-row">
      <div class="partner-scroll" :class="`scroll-1`">
        <div class="partner-item" v-for="i in partners2" :key="i.id">
          <el-image
            :src="i.casePh"
            fit="contain"
            class="partner-logo"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="partner-row">
      <div class="partner-scroll" :class="`scroll-2`">
        <div class="partner-item" v-for="i in partners3" :key="i.id">
          <el-image
            :src="i.casePh"
            fit="contain"
            class="partner-logo"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="partner-row">
      <div class="partner-scroll" :class="`scroll-3`">
        <div class="partner-item" v-for="i in partners4" :key="i.id">
          <el-image
            :src="i.casePh"
            fit="contain"
            class="partner-logo"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPartners } from "@/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PartnerSection",
  data() {
    return {
      partners1: [],
      partners2: [],
      partners3: [],
      partners4: [],
    };
  },
  created() {
    getPartners().then((res) => {
      res.rows.forEach((item, index) => {
        //  取余
        if (index % 4 === 0) {
          this.partners1.push(item);
        } else if (index % 4 === 1) {
          this.partners2.push(item);
        } else if (index % 4 === 2) {
          this.partners3.push(item);
        } else if (index % 4 === 3) {
          this.partners4.push(item);
        }
      });
    });
  },
};
</script>

<style scoped>
.partner-rows {
  display: flex;
  flex-direction: column;
  gap: 80px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  padding: 40px 0;
  background: rgba(255, 255, 255, 0.6);
}

.partner-row {
  width: 100%;
  overflow: hidden;
}

.partner-scroll {
  display: flex;
  gap: 80px;
  animation: scroll 40s linear infinite;
}

.scroll-2 {
  animation-delay: -10s;
}

.scroll-3 {
  animation-delay: -20s;
}

.scroll-4 {
  animation-delay: -30s;
}

.partner-row:hover .partner-scroll {
  animation-play-state: paused;
}

.partner-item {
  flex: 0 0 auto;
  transition: all 0.3s ease;
}

.partner-logo {
  width: auto;
  height: 64px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
<template>
  <div class="solution-item">
    <slot name="icon"></slot>
    <div class="content">
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    desc: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style scoped>
.solution-item {
  display: flex;
  align-items: flex-start;
}

.content {
  flex: 1;
  text-align: left;
  margin-left: 12px;
}

.title {
  color: #1f2329;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.desc {
  color: #50627f;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
}
</style>
<template>
  <div class="medical-detail-cntainer">
    <TopBg
      title="互联网医院"
      sub-title="充分利用信息化技术满足人们“足不出户即能看病”的需求。"
      :bg="topBg"
    />
    <div class="main-content">
      <!-- 产品简介 -->
      <div class="product-intro">
        <h1>产品简介</h1>
        <div class="rows-2">
          <info-item-vue
            title="产品背景"
            desc="“互联网+”医疗健康不仅是推进健康中国战略的重要举措，也是提升医疗卫生现代化管理水平、优化资源配置、创新服务模式、提高服务效率、降低服务成本的有效途径。近年来，一系列国家政策文件的颁布与实施，为我国“互联网+”医疗健康服务体系的建设和发展提供了有力支撑。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M1.88889 7.96777H11.3369C11.8278 7.96777 12.2258 7.56976 12.2258 7.07883V1.88889C12.2258 1.39796 11.8278 1 11.3369 1H1.88889C1.39796 1 1 1.39796 1 1.88889V7.07885C1 7.56976 1.39796 7.96777 1.88889 7.96777ZM24.1111 1H14.6631C14.1722 1 13.7742 1.39796 13.7742 1.88889V7.07885C13.7742 7.56978 14.1722 7.96779 14.6631 7.96779H24.1111C24.602 7.96779 25 7.56978 25 7.07885V1.88889C25 1.39796 24.602 1 24.1111 1ZM24.1111 9.51612H1.88889C1.39796 9.51612 1 9.91408 1 10.405V15.595C1 16.0859 1.39796 16.4839 1.88889 16.4839H24.1111C24.602 16.4839 25 16.0859 25 15.595V10.405C25 9.91408 24.602 9.51612 24.1111 9.51612ZM11.3369 18.0323H1.88889C1.39796 18.0323 1 18.4302 1 18.9211V24.1111C1 24.602 1.39796 25 1.88889 25H11.3369C11.8278 25 12.2258 24.602 12.2258 24.1111V18.9211C12.2258 18.4302 11.8278 18.0323 11.3369 18.0323ZM24.1111 18.0323H14.6631C14.1722 18.0323 13.7742 18.4302 13.7742 18.9212V24.1111C13.7742 24.602 14.1722 25 14.6631 25H24.1111C24.602 25 25 24.602 25 24.1111V18.9211C25 18.4302 24.602 18.0323 24.1111 18.0323Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M24.1111 9.51562H1.88889C1.39796 9.51562 1 9.91359 1 10.4045V15.5945C1 16.0854 1.39796 16.4834 1.88889 16.4834H24.1111C24.602 16.4834 25 16.0854 25 15.5945V10.4045C25 9.91359 24.602 9.51562 24.1111 9.51562Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
          <info-item-vue
            title="产品概述"
            desc="依托互联网和信息技术手段，向患者提供包括问诊、开方以及续方等一系列医疗服务，拓展医疗服务空间和内容，积极为患者提供在线便捷高效服务，以及随访管理和远程指导，逐步实现患者居家康复。与线下依托的实体医疗机构之间实现数据共享和业务协同，提供线上线下无缝衔接的连续服务。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M15.5495 15.2343H4.79366C4.25764 15.264 3.79607 14.862 3.76629 14.3558C3.73651 13.8347 4.1683 13.4029 4.68943 13.388H15.5198C16.0558 13.3582 16.5173 13.7602 16.5471 14.2665C16.5769 14.7876 16.1451 15.2194 15.624 15.2343H15.5495ZM15.5495 19.8798H4.79366C4.25764 19.9095 3.79607 19.5075 3.76629 19.0013C3.73651 18.4802 4.1683 18.0484 4.68943 18.0335H15.5198C16.0558 18.0037 16.5173 18.4057 16.5471 18.9119C16.5769 19.4331 16.1451 19.8649 15.624 19.8798C15.6091 19.8946 15.5793 19.8946 15.5495 19.8798ZM4.31719 5.92839C4.31719 4.90102 5.18078 4.06722 6.26771 4.06722H8.70957C9.7816 4.06722 10.6601 4.90102 10.6601 5.92839V8.25114C10.6601 9.27851 9.79649 10.1123 8.72446 10.1123H6.2826C5.21056 10.1123 4.33208 9.27851 4.33208 8.25114C4.31719 8.25114 4.31719 5.92839 4.31719 5.92839ZM19.4506 22.679V3.32275C19.4506 2.29538 18.0807 1 17.0087 1H2.5C1.42796 1 0.0581384 2.29538 0.0581384 3.32275V22.679C0.0581384 23.7063 1.42796 25.0017 2.5 25.0017H20.4447C20.668 25.0017 20.8467 24.823 20.8616 24.5997C20.8616 24.4657 20.8021 24.3317 20.6829 24.2572C20.0725 23.8106 19.4506 23.215 19.4506 22.679Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M21.0173 8.41878C21.0173 7.78622 21.5301 7.27344 22.1627 7.27344H24.8547C25.4872 7.27344 26 7.78622 26 8.41878V21.8547C26 22.4872 25.4872 23 24.8547 23H22.1627C21.5301 23 21.0173 22.4872 21.0173 21.8547V8.41878Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
        </div>
      </div>
      <!-- 系统架构 -->
      <div class="system">
        <h1>系统架构</h1>
        <img src="../../assets/medical/solution0401.png" alt="" srcset="" />
      </div>
      <!-- 产品特色 -->
      <div class="solution">
        <h1>产品特色</h1>
        <div class="rows-4">
          <feature-item title="总体规划，分步实施">
            <template #icon
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M10.0939 18.7947C9.73031 17.7948 8.91222 16.9768 7.91233 16.6132V8.88674C9.82121 8.25045 10.8211 6.15977 10.0939 4.2509C9.45761 2.34202 7.36694 1.34213 5.45806 1.97842C3.54918 2.70561 2.54929 4.79629 3.27648 6.61427C3.64008 7.70506 4.45817 8.52315 5.45806 8.88674V16.6132C3.54918 17.2495 2.54929 19.3401 3.27648 21.249C3.64008 22.2489 4.45817 23.067 5.45806 23.4306V31.157C3.54918 31.7933 2.54929 33.884 3.27648 35.7928C3.91278 37.7017 6.00345 38.7016 7.91233 37.9744C9.82121 37.3381 10.8211 35.2475 10.0939 33.3386C9.73031 32.3387 8.91222 31.5206 7.91233 31.157V23.4306C9.82121 22.7034 10.7302 20.7036 10.0939 18.7947ZM34.5457 4.2509H15.1843C13.8208 4.2509 12.73 5.34168 12.73 6.70517V11.5228H34.5457C35.9092 11.5228 37 10.432 37 9.06854V6.61427C37 5.34168 35.9092 4.2509 34.5457 4.2509ZM34.5457 28.43H15.1843C13.8208 28.43 12.73 29.5208 12.73 30.8843V35.7019H34.5457C35.9092 35.7019 37 34.6112 37 33.2477V30.8843C37 29.5208 35.9092 28.43 34.5457 28.43ZM34.5457 16.3405H15.1843C13.8208 16.3405 12.73 17.4312 12.73 18.7947V23.6124H34.5457C35.9092 23.6124 37 22.5216 37 21.1581V18.7038C37 17.4312 35.9092 16.3405 34.5457 16.3405Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M10.0939 18.7947C9.73031 17.7948 8.91222 16.9768 7.91233 16.6132V8.88674C9.82121 8.25045 10.8211 6.15977 10.0939 4.2509C9.45761 2.34202 7.36694 1.34213 5.45806 1.97842C3.54918 2.70561 2.54929 4.79629 3.27648 6.61427C3.64008 7.70506 4.45817 8.52315 5.45806 8.88674V16.6132C3.54918 17.2495 2.54929 19.3401 3.27648 21.249C3.64008 22.2489 4.45817 23.067 5.45806 23.4306V31.157C3.54918 31.7933 2.54929 33.884 3.27648 35.7928C3.91278 37.7017 6.00345 38.7016 7.91233 37.9744C9.82121 37.3381 10.8211 35.2475 10.0939 33.3386C9.73031 32.3387 8.91222 31.5206 7.91233 31.157V23.4306C9.82121 22.7034 10.7302 20.7036 10.0939 18.7947Z"
                  fill="#386FC6"
                /></svg
            ></template>
          </feature-item>
          <feature-item title="线上线下统一的患者智慧服务体系构建">
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M10.946 3H29.0397C29.2775 2.99947 29.513 3.04591 29.7327 3.13665C29.9525 3.2274 30.1522 3.36065 30.3203 3.52877C30.4884 3.6969 30.6217 3.89657 30.7124 4.11633C30.8032 4.33609 30.8496 4.57162 30.8491 4.80937V6.61875H9.13662V4.80937C9.13609 4.57162 9.18253 4.33609 9.27327 4.11633C9.36401 3.89657 9.49727 3.6969 9.66539 3.52877C9.83351 3.36065 10.0332 3.2274 10.2529 3.13665C10.4727 3.04591 10.7082 2.99947 10.946 3ZM32.0553 13.6552C31.9347 13.9165 31.5045 14.7368 31.1828 14.7408H8.73454C8.52947 14.7408 8.13945 14.3387 7.99471 13.9929C7.63661 13.0893 7.39607 12.1433 7.279 11.1784C7.24281 10.2536 7.80171 9.36899 8.79887 9.36899H30.9134C31.1489 9.36687 31.3825 9.41115 31.6009 9.49932C31.8193 9.58749 32.0182 9.71781 32.1862 9.88284C32.3542 10.0479 32.4881 10.2444 32.5801 10.4611C32.6722 10.6779 32.7207 10.9107 32.7228 11.1462V11.1784C32.6282 12.0332 32.4031 12.8685 32.0553 13.6552Z"
                  fill="#386FC6"
                />
                <path
                  d="M32.4575 36.9986H7.52837C6.88854 36.9986 6.27491 36.7445 5.82248 36.292C5.37005 35.8396 5.11588 35.226 5.11588 34.5862L3.10547 15.0128C3.10517 14.4572 3.29664 13.9185 3.64754 13.4878C3.99844 13.057 4.48727 12.7606 5.03144 12.6485C5.17254 12.6687 5.30638 12.7237 5.42083 12.8087C5.53527 12.8937 5.62671 13.0058 5.68684 13.1351C5.76725 13.3803 6.32213 17.4253 6.32213 17.4253H33.6637C33.6637 17.4253 34.3111 13.127 34.4076 12.9501C34.4635 12.8565 34.5431 12.7793 34.6384 12.7264C34.7338 12.6734 34.8414 12.6466 34.9504 12.6485C35.4953 12.7598 35.9851 13.0559 36.3368 13.4867C36.6885 13.9175 36.8805 14.4566 36.8804 15.0128L34.87 34.5862C34.87 35.226 34.6158 35.8396 34.1634 36.292C33.7109 36.7445 33.0973 36.9986 32.4575 36.9986Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M24.7938 28.7844H15.1921C14.8722 28.7844 14.5654 28.6573 14.3391 28.4311C14.1129 28.2049 13.9858 27.898 13.9858 27.5781V23.0266C13.9858 22.7066 14.1129 22.3998 14.3391 22.1736C14.5654 21.9474 14.8722 21.8203 15.1921 21.8203C15.512 21.8203 15.8188 21.9474 16.045 22.1736C16.2712 22.3998 16.3983 22.7066 16.3983 23.0266V26.3719H23.5876V23.1552C23.5876 22.8353 23.7147 22.5285 23.9409 22.3023C24.1671 22.0761 24.4739 21.949 24.7938 21.949C25.1137 21.949 25.4205 22.0761 25.6468 22.3023C25.873 22.5285 26.0001 22.8353 26.0001 23.1552V27.5781C26.0001 27.898 25.873 28.2049 25.6468 28.4311C25.4205 28.6573 25.1137 28.7844 24.7938 28.7844Z"
                  fill="white"
                />
              </svg>
            </template>
          </feature-item>
          <feature-item title="灵活的定制化服务">
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M0.676444 26.3211L8.36735 36.5806C8.50137 36.7817 8.87545 36.8887 9.25453 36.8887C9.61764 36.8887 9.97973 36.7907 10.1428 36.5806L17.2405 26.9122C18.0777 25.6159 17.6686 25.1368 16.0592 25.1368L12.5074 25.1368C12.5234 23.4074 12.6224 21.6379 12.7724 20.4457C13.4886 14.7333 17.1235 13.2099 17.2765 13.1439C17.8096 12.9238 17.7106 12.1216 17.0575 12.1216C7.87922 12.1216 5.73771 22.7512 5.40763 25.1368L1.85774 25.1368C1.80773 25.1328 1.72571 25.1268 1.62468 25.1268C1.09056 25.1268 0.0513163 25.2629 0.676444 26.3211Z"
                  fill="#386FC6"
                />
                <path
                  d="M19.4616 18.0856L24.0437 18.0856C24.0237 20.3181 23.8977 22.6057 23.7036 24.144C22.7774 31.5178 18.0863 33.4863 17.8872 33.5683C17.1971 33.8544 17.3271 34.8887 18.1703 34.8887C30.0172 34.8887 32.7839 21.1653 33.21 18.0866L37.7921 18.0866C37.8931 18.0963 37.9946 18.101 38.0962 18.1006C38.7833 18.1006 40.1267 17.9266 39.3204 16.5572L29.39 3.31097C29.217 3.0509 28.7319 2.91284 28.2438 2.91284C27.7756 2.91284 27.3055 3.03989 27.1005 3.31097L17.9352 15.796C16.852 17.4694 17.3821 18.0856 19.4616 18.0856Z"
                  fill="#1CB3B8"
                />
              </svg>
            </template>
          </feature-item>
          <feature-item title="业务全流程覆盖">
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M33.9996 21.8943C33.9996 29.6844 27.6844 35.9996 19.8943 35.9996C12.1042 35.9996 5.78906 29.6844 5.78906 21.8943C5.78906 14.1042 12.1042 7.78906 19.8943 7.78906C27.6844 7.78906 33.9996 14.1042 33.9996 21.8943ZM8.9373 21.8943C8.9373 27.9457 13.8429 32.8514 19.8943 32.8514C25.9457 32.8514 30.8514 27.9457 30.8514 21.8943C30.8514 15.8429 25.9457 10.9373 19.8943 10.9373C13.8429 10.9373 8.9373 15.8429 8.9373 21.8943Z"
                  fill="#1CB3B8"
                />
                <circle cx="20.0002" cy="10.0002" r="7.00015" fill="#386FC6" />
                <circle cx="8.60526" cy="29.6053" r="6.60526" fill="#1CB3B8" />
                <circle cx="31.6053" cy="29.6053" r="6.60526" fill="#1CB3B8" />
              </svg>
            </template>
          </feature-item>
        </div>
      </div>

      <!-- 产品价值 -->
      <div class="value">
        <h1>产品价值</h1>
        <p>
          我司互联网医院平台设计规划以患者实际需求为中心，以《互联网医院管理办法》为导向，以医院实际业务需求为指引进行规划设计。通过“互联网医院平台建设”促进医疗行业的数字化转型和创新发展，从而构建一个统一的、创新的“互联网+”医疗健康服务系统。
        </p>
        <div class="rows-4">
          <value-item-vue
            title="提升就诊体验"
            desc="一体化后更加增加了患者的可选性，可线上咨询，线下购药，亦可线下咨询，线上购药。线上功能再次丰富，可满足患者各方面诊疗需求,方便患者就医，提高患者就医体验。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M31.9999 32.5385C34.7832 32.5393 37.5043 31.7147 39.819 30.1689C42.1336 28.6232 43.9379 26.4257 45.0036 23.8544C46.0693 21.2832 46.3485 18.4536 45.806 15.7237C45.2635 12.9937 43.9236 10.486 41.9557 8.51755C39.9879 6.54914 37.4805 5.20852 34.7507 4.66522C32.0209 4.12192 29.1912 4.40036 26.6197 5.46532C24.0481 6.53028 21.8502 8.33392 20.3037 10.6481C18.7573 12.9624 17.9319 15.6832 17.9319 18.4665C17.9319 22.198 19.4139 25.7766 22.0521 28.4155C24.6902 31.0544 28.2685 32.5375 31.9999 32.5385ZM33.1999 52.5345C31.1134 50.5855 29.8642 47.9032 29.715 45.052C29.5658 42.2007 30.5283 39.4027 32.3999 37.2465C33.8851 35.5099 35.901 34.3108 38.1359 33.8345C36.1435 33.3727 34.1051 33.1392 32.0599 33.1385H31.9479C25.0966 33.1389 18.5033 35.7523 13.5123 40.4459C8.52136 45.1396 5.50846 51.5601 5.08789 58.3985C5.10329 58.7215 5.24226 59.0262 5.47605 59.2496C5.70984 59.4729 6.02055 59.5979 6.34389 59.5985H40.9119L33.1999 52.5345Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M57.0118 39.6191C54.3598 36.6871 49.9438 36.3191 47.1198 39.0671L46.0598 40.1671L44.9998 39.0671C44.3286 38.4269 43.5336 37.9308 42.6636 37.6092C41.7936 37.2877 40.867 37.1475 39.9409 37.1973C39.0147 37.2472 38.1085 37.4859 37.278 37.899C36.4476 38.312 35.7104 38.8906 35.1118 39.5991C32.4638 42.5351 32.8158 47.3031 35.6398 49.8671L46.2398 59.5991L56.8358 49.8831C59.4798 47.3191 59.6358 42.5511 57.0118 39.6151V39.6191Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="提升诊疗安全性"
            desc="线上线下互联互通，医生线上接诊，可查询患者历史诊疗信息，符合互联网医院管理要求，提升了线上诊疗的针对性和诊断正确率。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M41.2602 39.5682C31.914 39.5682 24.3914 31.9545 24.3914 22.75C24.3914 19.1136 25.5312 15.8182 27.4688 13.0909C24.5054 10.7045 20.8581 9.22727 16.7548 9.22727C7.52258 9.22727 0 16.7273 0 25.9318C0 30.7045 2.05161 35.1364 5.35699 38.2045L5.58494 38.3182L26.443 58.2045C27.1269 58.7727 28.0387 59 28.9505 59C29.8624 59 30.7742 58.6591 31.4581 58.2045L48.8968 41.7273L46.1613 39C44.4516 39.3409 42.8559 39.5682 41.2602 39.5682ZM62.8021 37.2955L56.7613 31.2727L53.6838 28.2045C54.4817 26.5 54.8236 24.6818 54.8236 22.6364C54.8236 16.6136 50.8344 11.5 45.3634 9.68182C43.8817 9.22727 42.4 9 40.8043 9C33.3957 9.22727 27.4688 15.1364 27.4688 22.5227C27.4688 30.0227 33.6236 36.1591 41.0322 36.1591C42.9699 36.1591 44.9075 35.7045 46.6172 35.0227L50.7204 39.1136L55.7355 44.1136C56.6473 45.0227 57.901 45.5909 59.1548 45.5909C60.4086 45.5909 61.6623 45.1364 62.5742 44.1136C63.486 43.2045 63.9419 41.9545 63.9419 40.7045C64.1699 39.4545 63.714 38.2045 62.8021 37.2955ZM41.3742 31.3864C36.5871 31.3864 32.7118 27.5227 32.7118 22.75C32.7118 17.9773 36.5871 14.1136 41.3742 14.1136C46.1613 14.1136 50.0365 17.9773 50.0365 22.75C50.0365 27.5227 46.1613 31.3864 41.3742 31.3864Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M62.8021 37.2955L56.7612 31.2727L53.6838 28.2045C54.4816 26.5 54.8236 24.6818 54.8236 22.6364C54.8236 16.6136 50.8343 11.5 45.3634 9.68182C43.8816 9.22727 42.3999 9 40.8042 9C33.3956 9.22727 27.4688 15.1364 27.4688 22.5227C27.4688 30.0227 33.6236 36.1591 41.0322 36.1591C42.9698 36.1591 44.9074 35.7045 46.6171 35.0227L50.7204 39.1136L55.7354 44.1136C56.6472 45.0227 57.901 45.5909 59.1548 45.5909C60.4085 45.5909 61.6623 45.1364 62.5741 44.1136C63.4859 43.2045 63.9419 41.9545 63.9419 40.7045C64.1698 39.4545 63.7139 38.2045 62.8021 37.2955ZM41.3741 31.3864C36.587 31.3864 32.7118 27.5227 32.7118 22.75C32.7118 17.9773 36.587 14.1136 41.3741 14.1136C46.1612 14.1136 50.0365 17.9773 50.0365 22.75C50.0365 27.5227 46.1612 31.3864 41.3741 31.3864Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="提高诊疗数据安全性"
            desc="用户数据全部存储于his库中，保障了患者数据信息的意外泄漏，具备对信息的安全性和保密性。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M30.6132 9.77191C30.9808 9.77191 31.3333 9.91793 31.5933 10.1779C31.8532 10.4378 31.9992 10.7903 31.9992 11.1579V30.5696C31.9992 32.0955 33.2396 33.3332 34.7628 33.3332H54.1745C54.3565 33.3332 54.5367 33.369 54.7049 33.4387C54.873 33.5083 55.0258 33.6104 55.1545 33.7391C55.2832 33.8678 55.3853 34.0206 55.4549 34.1887C55.5246 34.3569 55.5604 34.5371 55.5604 34.7191C55.5604 48.4969 44.391 59.6663 30.6132 59.6663C16.8354 59.6663 5.66602 48.4969 5.66602 34.7191C5.66602 20.9413 16.8354 9.77191 30.6132 9.77191ZM37.324 7C48.9273 7 58.3324 16.4051 58.3324 28.0083C58.3324 28.1618 58.3021 28.3138 58.2433 28.4556C58.1844 28.5973 58.0982 28.7261 57.9896 28.8345C57.8809 28.9429 57.7519 29.0288 57.61 29.0873C57.4681 29.1458 57.3161 29.1757 57.1626 29.1753H38.929C38.1938 29.1753 37.4888 28.8833 36.9689 28.3634C36.4491 27.8436 36.1571 27.1385 36.1571 26.4034V8.16698C36.1571 7.52251 36.6796 7 37.324 7Z"
                  fill="#386FC6"
                />
                <path
                  d="M30.6132 9.77148C30.9808 9.77148 31.3333 9.9175 31.5933 10.1774C31.8532 10.4373 31.9992 10.7899 31.9992 11.1574V30.5691C31.9992 32.0951 33.2396 33.3327 34.7628 33.3327H54.1745C54.3565 33.3327 54.5367 33.3686 54.7049 33.4382C54.873 33.5079 55.0258 33.61 55.1545 33.7387C55.2832 33.8674 55.3853 34.0202 55.4549 34.1883C55.5246 34.3565 55.5604 34.5367 55.5604 34.7187C55.5604 48.4965 44.391 59.6659 30.6132 59.6659C16.8354 59.6659 5.66602 48.4965 5.66602 34.7187C5.66602 20.9409 16.8354 9.77148 30.6132 9.77148Z"
                  fill="#1CB3B8"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="提高医生工作效率"
            desc="医生可通过线上手机APP、电脑端的医生工作站随时随地快速对患者的问询进行解答，医生在线下his系统中维护的处方模板、常用药品可线上直接调用，节省医生因重复开方浪费的时间。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M57.5429 4.28125H40.6634C38.3602 4.28125 37.3425 7.09338 38.8757 8.62667L43.7367 13.4876C34.2692 24.2273 20.7174 32.416 6.39559 32.416C4.86231 32.416 3.83789 33.4404 3.83789 34.9737C3.83789 36.507 4.86231 37.5314 6.39559 37.5314C23.5295 37.5314 39.3846 32.6704 50.8943 20.6519L55.5008 25.2584C57.0341 26.7917 59.8462 25.7673 59.8462 23.4707V6.83895C60.1006 5.30567 59.0762 4.28125 57.5429 4.28125Z"
                  fill="#386FC6"
                />
                <path
                  d="M7.05845 47.5359H15.7962C17.9454 47.5359 19.0167 48.6072 19.0167 50.7564V55.3161C19.0167 57.4654 17.9454 58.5367 15.7962 58.5367H7.05845C4.90918 58.5367 3.83789 57.4654 3.83789 55.3161V50.7564C3.83789 48.6072 4.91587 47.5359 7.05845 47.5359ZM27.5536 42.9829H36.2913C38.4405 42.9829 39.5118 44.0542 39.5118 46.2035V55.3228C39.5118 57.4721 38.4405 58.5434 36.2913 58.5434H27.5536C25.4043 58.5434 24.333 57.4721 24.333 55.3228V46.2035C24.333 44.0542 25.4043 42.9829 27.5536 42.9829ZM48.042 31.5938H56.7797C58.9289 31.5938 60.0002 32.665 60.0002 34.8143V55.3161C60.0002 57.4654 58.9289 58.5367 56.7797 58.5367H48.042C45.8927 58.5367 44.8214 57.4654 44.8214 55.3161V34.821C44.8214 32.6717 45.8994 31.5938 48.042 31.5938Z"
                  fill="#1CB3B8"
                />
              </svg>
            </template>
          </value-item-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBg from "../common/TopBg.vue";
import infoItemVue from "../common/info-item.vue";
import valueItemVue from "../common/value-item.vue";
import FeatureItem from "../common/feature-item.vue";

export default {
  name: "InternetHospital",
  components: {
    TopBg,
    infoItemVue,
    valueItemVue,
    FeatureItem,
  },
  data() {
    return {
      topBg: require("../../assets/medical/bg04.png"),
    };
  },
};
</script>

<style scoped>
.medical-detail-cntainer {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
}

h1 {
  color: #1f2329;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
}

.system img {
  width: 100%;
  margin-bottom: 80px;
}

.value p {
  color: #50627f;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px;
}

.value .rows-4 {
  padding-bottom: 16px;
  margin-bottom: 0px;
}
</style>

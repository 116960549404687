<template>
  <div class="news">
    <div class="news-content main-content">
      <h2 class="section-title">新闻中心</h2>
      <p class="section-sub-title">在这里读懂智先生</p>
      <div class="news-grid">
        <div class="news-card" v-for="(item, index) in newsItems" :key="index">
          <img :src="item.pictures" class="news-image" alt="news image" />
          <div class="card-content">
            <h3>{{ item.title }}</h3>
            <p class="news-desc">{{ item.digest }}</p>
            <div class="news-footer">
              <div class="news-date">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_2152_739)">
                    <path
                      d="M8.00065 14.6663C11.6826 14.6663 14.6673 11.6816 14.6673 7.99967C14.6673 4.31777 11.6826 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31777 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663Z"
                      stroke="#929EB0"
                      stroke-width="1.33333"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00235 4L8.00195 8.00293L10.8284 10.8294"
                      stroke="#929EB0"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2152_739">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span> {{ item.createTime }}</span>
              </div>

              <read-more
                :href="'/news-detail?id=' + item.id"
                class="read-more"
                width="100%"
                text="了解更多"
              ></read-more>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList } from "@/api/news.js";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "News",
  data() {
    return {
      newsItems: [
        {
          image: require("../../../assets/news1.png"),
          title: "公司荣获2023年度科技创新企业奖",
          description:
            "在2023年度科技创新评选中，我公司凭借在数字化转型领域的突出贡献获得此殊荣。",
          date: "2023-12-20",
        },
        {
          image: require("../../../assets/news1.png"),
          title: "成功举办数字化转型高峰论坛",
          description:
            "本次论坛汇集行业专家，共同探讨数字化转型趋势和创新方向。",
          date: "2023-12-15",
        },
        {
          image: require("../../../assets/news1.png"),
          title: "智慧医疗解决方案获得客户高度认可",
          description:
            "我公司智慧医疗解决方案在多家医院成功落地，获得客户一致好评。",
          date: "2023-12-10",
        },
      ],
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      try {
        const res = await getNewsList({
          pageSize: 3,
          pageNum: 1,
          type: 3,
          releaseStatus: 0,
        });
        this.newsItems = res.rows;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.news {
  padding: 64px 0;
  background-color: #fff;
}

.news-content {
  text-align: center;
}

.section-title {
  color: #1f2329;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.section-sub-title {
  color: #50627f;
  font-size: 18px;
  margin-bottom: 32px;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.news-card {
  padding: 0;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 484px;
  position: relative;
}

.news-card .news-image {
  width: 100%;
  height: 324px;
  object-fit: cover;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.news-card .card-content {
  text-align: left;
  padding: 16px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 160px;
  transition: all 0.6s ease-in-out;
}

.news-card:hover .news-image {
  transform: scale(1.05);
}

.news-card:hover .card-content {
  height: auto;
}

.news-card:hover .news-footer {
  opacity: 1;
}

.card-content h3 {
  font-size: 24px;
  color: #1f2329;
  margin-bottom: 10px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-desc {
  font-size: 14px;
  color: #50627f;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-card .news-footer {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.news-date {
  font-size: 14px;
  color: #929eb0;
  font-weight: 500;
  margin: 16px 0;
  display: flex;
  align-items: center;
}
.news-date span {
  display: inline-block;
}

.news-date svg {
  margin-right: 4px;
}

.read-more {
  display: block;
  border: none;
  transition: all 0.3s;
}
</style>

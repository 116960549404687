<template>
  <div class="medical-detail-cntainer">
    <TopBg
      title="DRG/DIP院内综合管理平台"
      sub-title="辅助医院做好DRG/DIP精细化运营管理。"
      :bg="topBg"
    />
    <div class="main-content">
      <!-- 产品简介 -->
      <div class="product-intro">
        <h1>产品简介</h1>
        <div class="rows-2">
          <info-item-vue
            title="产品背景"
            desc="2021年底，国家医疗保障局印发《DRG/DIP支付方式改革三年行动计划》，要求到2025年底，DRG/DIP支付方式覆盖全国所有符合条件的开展住院服务的医疗机构，基本实现病种、医保基金全覆盖，标志着医保支付改革正式进入高速发展阶段，也意味着基于DRG/DIP医保支付方式改革，促使医院加强信息化建设，实现精细化管理。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M1.88889 7.96777H11.3369C11.8278 7.96777 12.2258 7.56976 12.2258 7.07883V1.88889C12.2258 1.39796 11.8278 1 11.3369 1H1.88889C1.39796 1 1 1.39796 1 1.88889V7.07885C1 7.56976 1.39796 7.96777 1.88889 7.96777ZM24.1111 1H14.6631C14.1722 1 13.7742 1.39796 13.7742 1.88889V7.07885C13.7742 7.56978 14.1722 7.96779 14.6631 7.96779H24.1111C24.602 7.96779 25 7.56978 25 7.07885V1.88889C25 1.39796 24.602 1 24.1111 1ZM24.1111 9.51612H1.88889C1.39796 9.51612 1 9.91408 1 10.405V15.595C1 16.0859 1.39796 16.4839 1.88889 16.4839H24.1111C24.602 16.4839 25 16.0859 25 15.595V10.405C25 9.91408 24.602 9.51612 24.1111 9.51612ZM11.3369 18.0323H1.88889C1.39796 18.0323 1 18.4302 1 18.9211V24.1111C1 24.602 1.39796 25 1.88889 25H11.3369C11.8278 25 12.2258 24.602 12.2258 24.1111V18.9211C12.2258 18.4302 11.8278 18.0323 11.3369 18.0323ZM24.1111 18.0323H14.6631C14.1722 18.0323 13.7742 18.4302 13.7742 18.9212V24.1111C13.7742 24.602 14.1722 25 14.6631 25H24.1111C24.602 25 25 24.602 25 24.1111V18.9211C25 18.4302 24.602 18.0323 24.1111 18.0323Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M24.1111 9.51562H1.88889C1.39796 9.51562 1 9.91359 1 10.4045V15.5945C1 16.0854 1.39796 16.4834 1.88889 16.4834H24.1111C24.602 16.4834 25 16.0854 25 15.5945V10.4045C25 9.91359 24.602 9.51562 24.1111 9.51562Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
          <info-item-vue
            title="产品概述"
            desc="DRG/DIP院内综合管理平台立足医保支付改革背景，融合使用大数据、人工智能等技术，并结合多家三级医疗机构实际场景，以数据驱动、场景驱动为导向，帮助医院提高编码水平和病案质量、规范诊疗行为、加强成本管理、促进绩效评价。通过科学客观地评价医疗工作，引导医院发展方向，实现精细化管理，提高医疗效益。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M15.5495 15.2343H4.79366C4.25764 15.264 3.79607 14.862 3.76629 14.3558C3.73651 13.8347 4.1683 13.4029 4.68943 13.388H15.5198C16.0558 13.3582 16.5173 13.7602 16.5471 14.2665C16.5769 14.7876 16.1451 15.2194 15.624 15.2343H15.5495ZM15.5495 19.8798H4.79366C4.25764 19.9095 3.79607 19.5075 3.76629 19.0013C3.73651 18.4802 4.1683 18.0484 4.68943 18.0335H15.5198C16.0558 18.0037 16.5173 18.4057 16.5471 18.9119C16.5769 19.4331 16.1451 19.8649 15.624 19.8798C15.6091 19.8946 15.5793 19.8946 15.5495 19.8798ZM4.31719 5.92839C4.31719 4.90102 5.18078 4.06722 6.26771 4.06722H8.70957C9.7816 4.06722 10.6601 4.90102 10.6601 5.92839V8.25114C10.6601 9.27851 9.79649 10.1123 8.72446 10.1123H6.2826C5.21056 10.1123 4.33208 9.27851 4.33208 8.25114C4.31719 8.25114 4.31719 5.92839 4.31719 5.92839ZM19.4506 22.679V3.32275C19.4506 2.29538 18.0807 1 17.0087 1H2.5C1.42796 1 0.0581384 2.29538 0.0581384 3.32275V22.679C0.0581384 23.7063 1.42796 25.0017 2.5 25.0017H20.4447C20.668 25.0017 20.8467 24.823 20.8616 24.5997C20.8616 24.4657 20.8021 24.3317 20.6829 24.2572C20.0725 23.8106 19.4506 23.215 19.4506 22.679Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M21.0173 8.41878C21.0173 7.78622 21.5301 7.27344 22.1627 7.27344H24.8547C25.4872 7.27344 26 7.78622 26 8.41878V21.8547C26 22.4872 25.4872 23 24.8547 23H22.1627C21.5301 23 21.0173 22.4872 21.0173 21.8547V8.41878Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </info-item-vue>
        </div>
      </div>
      <!-- 解决方案 -->
      <div class="solution">
        <h1>解决方案</h1>
        <div class="rows-4">
          <solution-item
            title="DRG/DIP分组器"
            desc="平台遵循国家统一标准，并支持本地化配置，顺利衔接各地市特色制定下发的分组标准、技术规范和支付测算，已成功切换2.0版分组方案。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20.337 0.328746C20.6489 0.145034 21.0357 0.144237 21.3484 0.32666L28.4998 4.49902L20.9998 9.49902L14.6279 5.3708C14.0041 4.9666 14.0236 4.04716 14.6642 3.6699L20.337 0.328746Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M20.8421 17.4863L28.4141 21.9139L21.3432 26.0487C21.0333 26.2299 20.6501 26.2311 20.3391 26.0519L14.7518 22.832C14.0891 22.4501 14.0831 21.4958 14.7409 21.1056L20.8421 17.4863Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M20.8423 8.99975L28.5 4.5L28.4143 21.9459L20.8423 18.0958V8.99975Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M11.5875 5.97362C11.2788 5.79181 10.8964 5.78902 10.5851 5.96629L3.5 10.001L11.0884 14.6311L17.2051 11.0024C17.8594 10.6143 17.8579 9.66679 17.2024 9.28073L11.5875 5.97362Z"
                  fill="#386FC6"
                />
                <path
                  d="M11.0886 23.1328L3.5166 27.5602L10.5875 31.6948C10.8974 31.876 11.2806 31.8772 11.5916 31.698L17.1787 28.4784C17.8414 28.0964 17.8475 27.1421 17.1896 26.7519L11.0886 23.1328Z"
                  fill="#386FC6"
                />
                <path
                  d="M11.0886 14.4996L3.5166 10.0078V27.5926L11.0886 23.7425V14.4996Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </solution-item>
          <solution-item
            title="医生助手系统"
            desc="医生可实时查看预入组情况，并提示相关指标及智能推荐服务，另外医生还可通过助手进行模拟分组演练，帮助医生找到最合适的诊断与手术操作，最大程度地接回医保基金。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_2152_2895)">
                  <path
                    d="M14.7178 11.0904C15.0454 9.7465 16.2493 8.80078 17.6325 8.80078H18.3635C18.6876 8.80078 18.926 9.10433 18.8493 9.41918L16.2822 19.9512C15.9546 21.2951 14.7507 22.2408 13.3675 22.2408H12.6365C12.3124 22.2408 12.074 21.9373 12.1507 21.6224L14.7178 11.0904Z"
                    fill="#1CB3B8"
                  />
                  <path
                    d="M19.9899 17.4386H8.6188C8.30252 17.4386 8.06871 17.144 8.14054 16.836C8.45158 15.5022 9.64058 14.5586 11.0101 14.5586H22.3812C22.6975 14.5586 22.9313 14.8532 22.8595 15.1612C22.5484 16.495 21.3594 17.4386 19.9899 17.4386Z"
                    fill="#1CB3B8"
                  />
                  <path
                    d="M4.07541 10.9314C4.59777 7.79719 7.30951 5.5 10.487 5.5H29.3489C29.6579 5.5 29.8929 5.77744 29.8421 6.0822L27.3444 21.0686C26.822 24.2028 24.1103 26.5 20.9328 26.5H2.07088C1.76191 26.5 1.52689 26.2226 1.57768 25.9178L4.07541 10.9314Z"
                    stroke="#386FC6"
                    stroke-width="3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2152_2895">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </template>
          </solution-item>
          <solution-item
            title="病案质控系统"
            desc="系统基于HQMS、DRG、规范填报、临床规则等规范，内置规则库，多层次全方位进行数据监控和规则校验，旨在帮助院方提高病案质量，实现“医保结算0打回”。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_2152_2903)">
                  <path
                    d="M7.10461 7.57067C7.31798 6.09501 8.58272 5 10.0737 5H30.845C31.4542 5 31.9219 5.54014 31.8347 6.14311L30.0582 18.4293C29.8448 19.905 28.58 21 27.089 21H26.4475L28.1317 9.43827C28.2195 8.83497 27.7518 8.29412 27.1421 8.29412H7L7.10461 7.57067Z"
                    fill="#386FC6"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.76331 9.98047C3.26067 9.98047 1.98982 11.0922 1.78997 12.5815L0.00709909 25.867C-0.0733855 26.4668 0.393084 27 0.998214 27H21.4407C22.9433 27 24.2141 25.8883 24.414 24.399L26.1969 11.1135C26.2774 10.5137 25.8109 9.98047 25.2058 9.98047H4.76331ZM17.313 15.8352L17.2139 16.4873H19.3926L19.041 18.9918H19.542C20.0405 18.9918 20.4629 18.6246 20.5323 18.1309L20.763 16.4873H22.2927C22.787 16.4873 23.2071 16.1263 23.2814 15.6377L23.3805 14.9856H20.9738L21.325 12.4844H20.824C20.3254 12.4844 19.903 12.8516 19.8337 13.3453L19.6035 14.9856H18.3017C17.8075 14.9856 17.3874 15.3466 17.313 15.8352ZM3.08829 20.2623C3.06988 20.3833 3.16358 20.4923 3.28601 20.4923H10.4161C10.9103 20.4923 11.3304 20.1313 11.4047 19.6427L11.4689 19.2207C11.4873 19.0996 11.3936 18.9906 11.2712 18.9906H4.1411C3.64688 18.9906 3.22678 19.3516 3.15247 19.8402L3.08829 20.2623ZM3.74297 16.4873C3.62053 16.4873 3.52684 16.3783 3.54525 16.2572L3.60943 15.8352C3.68374 15.3466 4.10384 14.9856 4.59806 14.9856L8.07384 14.9856C8.19628 14.9856 8.28998 15.0946 8.27157 15.2157L8.20738 15.6377C8.13307 16.1263 7.71297 16.4873 7.21875 16.4873H3.74297ZM2.17471 24.267C2.1563 24.388 2.25 24.497 2.37244 24.497L15.8976 24.497C16.3918 24.497 16.8119 24.136 16.8862 23.6474L16.9504 23.2254C16.9688 23.1043 16.8751 22.9953 16.7527 22.9953L3.22753 22.9953C2.73331 22.9953 2.31321 23.3564 2.2389 23.845L2.17471 24.267Z"
                    fill="#1CB3B8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2152_2903">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </template>
          </solution-item>
          <solution-item
            title="绩效评价系统"
            desc="系统提供全院、科室、医疗组、个体医生等多角度的绩效评价指标，提高各部门积极性、合理性和规范性，助力建设院内符合DRG/DIP支付方式改革的更精细有效的绩效评价体系。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M15.551 8.32058C15.6923 7.89314 16.3077 7.89314 16.449 8.32058L18.0139 13.0537C18.0771 13.2448 18.2584 13.3743 18.4629 13.3743H23.527C23.9843 13.3743 24.1745 13.9494 23.8045 14.2136L19.7075 17.1388C19.5421 17.2569 19.4728 17.4663 19.536 17.6575L21.1009 22.3906C21.2423 22.818 20.7444 23.1735 20.3744 22.9093L16.2775 19.9841C16.112 19.8659 15.888 19.8659 15.7225 19.9841L11.6256 22.9093C11.2556 23.1735 10.7577 22.818 10.8991 22.3906L12.464 17.6575C12.5272 17.4663 12.4579 17.2569 12.2925 17.1388L8.19551 14.2136C7.82552 13.9494 8.01567 13.3743 8.47301 13.3743H13.5371C13.7416 13.3743 13.9229 13.2448 13.9861 13.0537L15.551 8.32058Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM4.76274 16C4.76274 22.2062 9.79383 27.2373 16 27.2373C22.2062 27.2373 27.2373 22.2062 27.2373 16C27.2373 9.79383 22.2062 4.76274 16 4.76274C9.79383 4.76274 4.76274 9.79383 4.76274 16Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </solution-item>
        </div>
      </div>

      <!-- 产品价值 -->
      <div class="value">
        <h1>产品价值</h1>
        <div class="rows-3">
          <value-item-vue
            title="精准的分组与付费规则"
            desc="我们为每个地区均配备独立的项目组，并有局端专家支持，确保精确分组和精准计算费用。综合测算的准确性高达98%以上。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M31.9036 3.00072C36.197 3.00072 40.2722 3.93328 43.9383 5.6076L39.3366 9.97734C38.7802 10.5335 38.3785 11.2251 38.1711 11.9839C37.9636 12.7427 37.9576 13.5425 38.1537 14.3043L38.2377 14.5913L38.9306 16.7479C36.7274 15.732 34.3297 15.2076 31.9036 15.211C22.6315 15.211 15.1145 22.7279 15.1145 32C15.1145 41.2722 22.6315 48.7891 31.9036 48.7891C41.1757 48.7891 48.6927 41.2722 48.6927 32C48.6927 29.9395 48.3218 27.9645 47.6411 26.1391L51.5422 27.2457C53.0822 27.76 54.5337 26.7038 55.712 25.5606L55.9059 25.3683L59.1416 22.0212C60.3109 25.218 60.9072 28.5961 60.9029 32C60.9029 48.0153 47.9188 60.9993 31.9036 60.9993C15.8884 60.9993 2.9043 48.0153 2.9043 32C2.9043 15.9848 15.8884 3.00072 31.9036 3.00072ZM52.5481 3.43571C52.7112 3.59903 52.834 3.79814 52.9067 4.01722L55.0283 10.4047L61.4112 12.5232C61.6512 12.6031 61.8668 12.7429 62.0376 12.9295C62.2085 13.1161 62.3288 13.3433 62.3872 13.5894C62.4456 13.8356 62.4401 14.0925 62.3713 14.336C62.3026 14.5794 62.1728 14.8013 61.9942 14.9805L53.4089 23.5673C53.0188 23.9577 52.5277 24.2315 51.9907 24.3583C51.4536 24.485 50.8919 24.4595 50.3685 24.2847L45.8172 22.7615L35.704 32.8746C35.4987 33.0875 35.2529 33.2574 34.9812 33.3743C34.7095 33.4912 34.4172 33.5528 34.1213 33.5555C33.8255 33.5581 33.5322 33.5018 33.2583 33.3899C32.9845 33.2779 32.7358 33.1125 32.5266 32.9033C32.3174 32.6941 32.152 32.4454 32.04 32.1716C31.9281 31.8978 31.8718 31.6044 31.8744 31.3086C31.8771 31.0128 31.9387 30.7204 32.0556 30.4487C32.1725 30.177 32.3424 29.9312 32.5553 29.7259L42.6471 19.6341L41.1361 15.0553C40.9643 14.5332 40.9408 13.9736 41.0683 13.439C41.1957 12.9043 41.4691 12.4156 41.858 12.0271L50.4494 3.43418C50.7279 3.15616 51.1053 3 51.4987 3C51.8922 3 52.2696 3.15616 52.5481 3.43418V3.43571Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M52.5482 3.43571C52.7113 3.59903 52.8341 3.79814 52.9069 4.01722L55.0284 10.4047L61.4113 12.5232C61.6513 12.6031 61.867 12.7429 62.0378 12.9295C62.2086 13.1161 62.3289 13.3433 62.3873 13.5894C62.4457 13.8356 62.4403 14.0925 62.3715 14.336C62.3027 14.5794 62.1729 14.8013 61.9943 14.9805L53.409 23.5673C53.019 23.9577 52.5279 24.2315 51.9908 24.3583C51.4538 24.485 50.892 24.4595 50.3687 24.2847L45.8173 22.7615L35.7042 32.8746C35.4988 33.0875 35.2531 33.2574 34.9814 33.3743C34.7096 33.4912 34.4173 33.5528 34.1215 33.5555C33.8257 33.5581 33.5323 33.5018 33.2585 33.3899C32.9847 33.2779 32.7359 33.1125 32.5267 32.9033C32.3176 32.6941 32.1522 32.4454 32.0402 32.1716C31.9282 31.8978 31.8719 31.6044 31.8746 31.3086C31.8773 31.0128 31.9389 30.7204 32.0558 30.4487C32.1727 30.177 32.3425 29.9312 32.5555 29.7259L42.6472 19.6341L41.1362 15.0553C40.9644 14.5332 40.941 13.9736 41.0684 13.439C41.1959 12.9043 41.4693 12.4156 41.8581 12.0271L50.4496 3.43418C50.728 3.15616 51.1054 3 51.4989 3C51.8924 3 52.2698 3.15616 52.5482 3.43418V3.43571Z"
                  fill="#386FC6"
                />
                <circle cx="31.9034" cy="33.1456" r="6.86826" fill="#386FC6" />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="双端纠错提醒：医生端和管理端"
            desc="手术操作漏编码规则：从医嘱和手术记录中提取手术操作的漏编码，影响分组的着重标识。基于本地分组和付费规则重点发现编码问题、通过大数据推荐更优的入组方案，识别低标入院、分解住院等问题。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M21.2866 30.6783C21.0745 30.8928 20.9069 31.1471 20.7933 31.4266C20.6798 31.7061 20.6226 32.0053 20.625 32.307V42.1805C20.625 42.7879 20.8663 43.3704 21.2958 43.7999C21.7253 44.2295 22.3079 44.4707 22.9153 44.4707H32.6616C32.9632 44.4732 33.2624 44.4159 33.5419 44.3024C33.8214 44.1889 34.0757 44.0212 34.2902 43.8091L57.5999 20.4994C57.685 20.414 57.7861 20.3463 57.8975 20.3C58.0088 20.2538 58.1282 20.23 58.2488 20.23C58.3694 20.23 58.4887 20.2538 58.6001 20.3C58.7114 20.3463 58.8126 20.414 58.8977 20.4994C59.059 20.6824 59.1494 20.9171 59.1522 21.1611V48.4914C59.1555 48.7943 59.0988 49.0949 58.9852 49.3757C58.8716 49.6565 58.7034 49.912 58.4904 50.1273C58.2774 50.3427 58.0238 50.5137 57.7443 50.6304C57.4647 50.7471 57.1648 50.8071 56.8619 50.8071H40.7792L33.1451 58.4413C32.7159 58.8686 32.1348 59.1085 31.5292 59.1085C30.9235 59.1085 30.3425 58.8686 29.9133 58.4413L22.2791 50.8071H6.29821C5.99529 50.8071 5.69539 50.7471 5.41585 50.6304C5.13631 50.5137 4.8827 50.3427 4.66969 50.1273C4.45668 49.912 4.28852 49.6565 4.17493 49.3757C4.06134 49.0949 4.00459 48.7943 4.00795 48.4914V11.6438C4.00795 11.0364 4.24925 10.4538 4.67875 10.0243C5.10826 9.59482 5.69079 9.35353 6.29821 9.35353H40.2703C40.5133 9.35353 40.7463 9.45004 40.9181 9.62185C41.0899 9.79365 41.1864 10.0267 41.1864 10.2696C41.1836 10.5135 41.0933 10.7483 40.9319 10.9313L21.2866 30.6783ZM52.8158 5.63823C53.2493 5.20932 53.8346 4.96875 54.4444 4.96875C55.0543 4.96875 55.6395 5.20932 56.073 5.63823L59.3303 8.89548C59.7592 9.32902 59.9998 9.91425 59.9998 10.5241C59.9998 11.134 59.7592 11.7192 59.3303 12.1527L32.3816 39.0759C31.951 39.5113 31.3654 39.7583 30.753 39.763H27.5212C26.9071 39.763 26.318 39.519 25.8838 39.0847C25.4495 38.6505 25.2055 38.0615 25.2055 37.4473V34.2155C25.2102 33.6031 25.4572 33.0175 25.8926 32.5869L52.8158 5.63823Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M52.8158 5.63823C53.2494 5.20932 53.8346 4.96875 54.4445 4.96875C55.0543 4.96875 55.6396 5.20932 56.0731 5.63823L59.3303 8.89548C59.7592 9.32902 59.9998 9.91425 59.9998 10.5241C59.9998 11.134 59.7592 11.7192 59.3303 12.1527L32.3817 39.0759C31.951 39.5113 31.3654 39.7583 30.7531 39.763H27.5213C26.9071 39.763 26.3181 39.519 25.8838 39.0847C25.4495 38.6505 25.2056 38.0615 25.2056 37.4473V34.2155C25.2102 33.6031 25.4573 33.0175 25.8926 32.5869L52.8158 5.63823Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </value-item-vue>
          <value-item-vue
            title="全方位运营分析系统"
            desc="多角度可视化数据分析，全面监控医院运营指标，支持从全院/科室/诊疗组/病组/病例等维度逐层下钻分析，直至最深层次原因，对症下药，助力医院学科发展与绩效考核。方便科室和管理者对相同专业的不同科室、不同诊疗组进行对比，从病组层面深度剖析，及时发现问题、解决问题、取长补短。另外，支持医院定制化报表服务。"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
              >
                <path
                  d="M46.1053 29.1227H59.8595C58.5966 15.9297 47.9298 5.26332 34.7367 4V17.7546C37.5483 18.3021 40.1323 19.6765 42.1578 21.7019C44.1832 23.7272 45.5578 26.3112 46.1053 29.1227ZM29.1227 17.7546V4C15.9297 5.26332 5.26332 15.9297 4 29.1227H17.7546C18.3021 26.3113 19.6766 23.7273 21.702 21.702C23.7273 19.6766 26.3113 18.3021 29.1227 17.7546ZM34.7367 46.1053V59.8595C48.0703 58.5966 58.5966 47.9298 60 34.5967H46.2458C44.9825 40.4913 40.4913 44.9825 34.7367 46.1053ZM17.7546 34.7367H4C5.26332 48.0703 15.9297 58.5966 29.2633 60V46.2458C23.3687 44.9825 18.8774 40.4913 17.7546 34.7367Z"
                  fill="#1CB3B8"
                />
                <path
                  d="M46.1053 29.1227H59.8595C58.5966 15.9297 47.9298 5.26332 34.7367 4V17.7546C37.5483 18.3021 40.1324 19.6765 42.1578 21.7019C44.1832 23.7272 45.5578 26.3112 46.1053 29.1227ZM17.7546 34.7367H4C5.26332 48.0703 15.9297 58.5966 29.2633 60V46.2458C23.3687 44.9825 18.8774 40.4913 17.7546 34.7367Z"
                  fill="#386FC6"
                />
              </svg>
            </template>
          </value-item-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBg from "../common/TopBg.vue";
import infoItemVue from "../common/info-item.vue";
import valueItemVue from "../common/value-item.vue";
import SolutionItem from "../common/solution-item.vue";

export default {
  name: "DrgPlatform",
  components: {
    TopBg,
    infoItemVue,
    valueItemVue,
    SolutionItem,
  },
  data() {
    return {
      topBg: require("../../assets/medical/bg03.png"),
    };
  },
};
</script>

<style scoped>
.medical-detail-cntainer {
  background: #f6f8fd;
}

.main-content {
  margin-top: -235px !important;
}

h1 {
  color: #1f2329;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
}

.value .rows-3 {
  padding-bottom: 16px;
  margin-bottom: 0px;
}
</style>

<template>
  <div class="point-analysis-item">
    <slot name="icon"></slot>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style scoped>
.point-analysis-item {
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  width: 100%;
  padding: 24px 16px 16px;
  text-align: left;
}

.title {
  color: #50627f;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
  margin-top: 14px;
}
</style>
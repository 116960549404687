<template>
  <div class="feature-item">
    <slot name="icon"></slot>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>

<style scoped>
.feature-item {
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: #fff;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
}

.title {
  text-align: left;
  color: #50627f;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex: 1;
  padding-left: 16px;
}
</style>
import { render, staticRenderFns } from "./TopBg.vue?vue&type=template&id=63f9accd&scoped=true"
import script from "./TopBg.vue?vue&type=script&lang=js"
export * from "./TopBg.vue?vue&type=script&lang=js"
import style0 from "./TopBg.vue?vue&type=style&index=0&id=63f9accd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f9accd",
  null
  
)

export default component.exports
<template>
  <div class="timeline-container">
    <div class="timeline-wrapper">
      <div class="timeline-content" :style="{ '--duration': duration + 's' }">
        <div
          class="timeline-item"
          v-for="(item, index) in displayItems"
          :key="index"
        >
          <div
            class="year"
            :class="{ 'year-green': item.year == '2025' ? true : false }"
          >
            {{ item.year }}
          </div>
          <div class="events">
            <div
              class="event"
              v-for="(event, eventIndex) in item.events"
              :key="eventIndex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19.49 1.36914C13.41 5.09914 9 9.80914 7.01 12.1691L2.15 8.35914L0 10.0891L8.39 18.6291C9.83 14.9291 14.41 7.69914 20 2.55914L19.49 1.36914Z"
                  fill="#1CB3B8"
                />
              </svg>
              <span> {{ event }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="timeline-content second"
        :style="{ '--duration': duration + 's' }"
      >
        <div
          class="timeline-item"
          v-for="(item, index) in displayItems"
          :key="index"
        >
          <div
            class="year"
            :class="{ 'year-green': item.year == '2025' ? true : false }"
          >
            {{ item.year }}
          </div>
          <div class="events">
            <div
              class="event"
              v-for="(event, eventIndex) in item.events"
              :key="eventIndex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19.49 1.36914C13.41 5.09914 9 9.80914 7.01 12.1691L2.15 8.35914L0 10.0891L8.39 18.6291C9.83 14.9291 14.41 7.69914 20 2.55914L19.49 1.36914Z"
                  fill="#1CB3B8"
                />
              </svg>
              <span> {{ event }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Timeline",
  data() {
    return {
      duration: 50,
      timelineData: [
        {
          year: "2019",
          events: ["公司成立于江北新区", "打造智维品牌"],
        },
        {
          year: "2020",
          events: [
            "江苏省双软企业",
            "江苏省民营科技企业",
            "获种子轮投资",
            "获区、市、省优秀创业项目",
          ],
        },
        {
          year: "2021",
          events: [
            "国家高新技术企业",
            "江苏省股权交易中心挂牌",
            "智维产品获批市级重点研发计划项目",
          ],
        },
        {
          year: "2022",
          events: [
            "三星级上云企业",
            "纳税信用A级纳税人",
            "江苏省优秀软件企业",
            "获Pre-A轮投资",
          ],
        },
        {
          year: "2023",
          events: [
            "科技型中小企业",
            "诚信经营示范单位",
            "获新一轮战略投资",
            "中国国际大学生创新大赛银奖 ",
            "江苏省“互联网+”大学生创新创业大赛一等奖",
            "研发成果获国家级智慧医疗大赛二等奖、省赛一等奖",
            "荣获赛迪网行业信息技术应用创新产品、创新人物、创新百家企业",
          ],
        },
        {
          year: "2024",
          events: [
            "江苏省专精特新中心企业",
            "获江苏省信创医疗产品认证",
            "第七届江苏智慧医疗创新大赛一等奖",
            "“数据要素x”大赛江苏赛区医疗健康组一等奖",
            "“数据要素X”大赛全国总决赛应用实践奖",
            "入选信通院数字医疗产品及服务高质量发展全景图",
          ],
        },
        {
          year: "2025",
          events: [
            "江苏省软件核心竞争力企业（创新型）",
            "入选江苏数字社会重大场景项目、十大典型场景项目 ",
          ],
        },
      ],
    };
  },
  computed: {
    displayItems() {
      return this.timelineData;
    },
  },
};
</script>

<style scoped>
.timeline-container {
  width: 100%;
  overflow: hidden;
}

.timeline-wrapper {
  position: relative;
  white-space: nowrap;
}

.timeline-content {
  display: inline-block;
  animation: scroll var(--duration) linear infinite;
}

.timeline-content.second {
  animation: scroll2 var(--duration) linear infinite;
  position: relative;
}

.timeline-content.second::before {
  height: 400px;
  content: "";
  width: 0;
  display: inline-block;
  border-left: 1px dashed #999c9e;
  position: absolute;
  margin-top: 25px;
}
.timeline-wrapper:hover .timeline-content,
.timeline-wrapper:hover .timeline-content.second {
  animation-play-state: paused;
}

.timeline-item {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 8px;
  vertical-align: top;
  position: relative;
}

.timeline-item::before {
  content: "";
  height: 3px;
  width: 500px;
  background: #d9dbdf;
  position: absolute;
  left: 0;
  top: 24px;
}

.year {
  color: #1f2329;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  border-radius: 21px;
  border: 1px solid #d9dbdf;
  background: #fff;
  line-height: 42px;
  padding: 0 16px;
  box-shadow: 0px 4px 10px 0px rgba(15, 33, 61, 0.06);
  position: absolute;
  top: 0;
  left: 0;
}
.timeline-content .year-green {
  background: #1cb3b8 !important;
  border: 1px solid #1cb3b8;
  color: #fff;
}

.events {
  text-align: left;
  border-radius: 8px;
  background: #eef1f8;
  margin-top: 70px;
  padding: 18px 16px;
  width: 264px;
}

.event {
  color: #50627f;
  font-size: 16px;
  white-space: normal;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.event:last-child {
  margin-bottom: 0;
}
.event svg {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.event span {
  display: inline-block;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
<template>
  <div class="smart">
    <div class="smart-content main-content">
      <h2 class="section-title">智维</h2>
      <div class="smart-content-bottom">
        <div class="smart-intro">
          <p class="intro-text">
            智维，提供新一代场景式 IT
            综合管理解决方案，深耕信息化部门日常运维、业务管理与运营分析等领域，全力助推服务优化、效能提升与信息化高质量建设。
          </p>
          <div class="intro-features">
            <div class="feature-item">
              <span class="feature-text"
                >面向信息化部门，国资业务运营管理，部门工作管理，关键指标维度化考核问题，以稳定运维、风险防控为基线，以改革要求、业务场景为导向，让信息化工作可见、可查、可控、可追溯。</span
              >
            </div>
          </div>
          <div class="card-footer">
            <green-button
              href="/smart"
              class="btn-more"
              text="了解更多"
              width="100%"
            ></green-button>
            <router-link class="btn-chat" to="/free">在线留言</router-link>
          </div>
        </div>
        <div class="smart-grid">
          <div
            class="smart-card"
            v-for="(item, index) in smartItems"
            :key="index"
          >
            <div class="card-content">
              <div class="title-wrapper">
                <h3>{{ item.title }}</h3>
                <router-link class="link-more" :to="item.link"
                  >了解更多</router-link
                >
              </div>
              <p>{{ item.description }}</p>
            </div>
            <div class="card-tags">
              <div
                class="tag"
                v-for="(tag, tagIndex) in item.tags"
                :key="tagIndex"
              >
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Smart",
  data() {
    return {
      smartItems: [
        {
          title: "数字化运维监控系统",
          description: "全域资产监测，保障业务系统安全、稳定、可靠运行",
          tags: ["资产动态摸底", "资产可用性监测", "业务健康实时感知"],
          link: "/smart",
        },
        {
          title: "IT服务流程管理系统",
          description: "聚焦IT管理落实，着力制度流程化",
          tags: ["工单闭环管理", "机房智能巡检", "项目全流程管理"],
          link: "/it-service",
        },
        {
          title: "数字网信管理系统",
          description: "让网络安全可见、可管、可追溯",
          tags: ["安全机制管理", "安全事件管理", "安全运营管理"],
          link: "/digital-management",
        },
      ],
    };
  },
};
</script>

<style scoped>
.smart {
  background: #f6f8fd;
  padding: 64px 0;
}

.smart-content-bottom {
  display: flex;
  gap: 16px;
}

.smart-intro {
  text-align: left;
  width: 544px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  position: relative;
  background: url("../../../assets/home/smart_bg.png"), rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.section-title {
  color: #1f2329;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: left;
}

.intro-text {
  color: #1f2329;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 170%;
  /* 34px */
  margin-bottom: 16px;
}

.intro-features {
  margin-bottom: 16px;
}

.feature-item {
  margin-bottom: 20px;
}

.feature-text {
  color: #50627f;
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  /* 27.2px */
}

.smart-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #d9dbdf;
  background: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.smart-card {
  border-bottom: 1px dashed #929eb0;
  padding-bottom: 34px;
}

.smart-card:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.card-content h3 {
  color: #1f2329;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}

.card-content p {
  color: #50627f;
  text-align: justify;
  font-size: 16px;
  line-height: 170%;
  /* 27.2px */
  margin-bottom: 18px;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  border-radius: 4px;
  background: rgba(56, 111, 198, 0.1);
  display: flex;
  height: 28px;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  color: #50627f;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
  /* 23.8px */
}

.card-footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
}

.card-footer .btn-more {
  border-radius: 8px;
  flex: 1;
  margin-right: 16px;
  color: #fff;
  line-height: 48px;
  text-align: left;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.card-footer .btn-chat {
  border-radius: 8px;
  height: 48px;
  flex: 1;
  color: #1f2329;
  line-height: 48px;
  padding-left: 24px;
  text-align: left;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  background: rgba(56, 111, 198, 0.1);
}

.card-footer .btn-chat:hover {
  color: #1cb3b8;
  transform: scale(1.01);
}

.card-footer .btn-chat::after {
  content: "→";
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.link-more {
  color: #50627f;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  padding-right: 20px;
}

.link-more::after {
  content: "→";
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.link-more:hover {
  color: #1cb3b8;
}
</style>
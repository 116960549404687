<template>
  <div class="input">
    <div class="input-wrapper">
      <input
        v-if="type == 'text'"
        type="text"
        :placeholder="label"
        :value="data"
        @input="$emit('update:data', $event.target.value)"
        class="form-input"
        maxlength="100"
      />
      <textarea
        v-else
        :placeholder="label"
        :value="data"
        @input="$emit('update:data', $event.target.value)"
        class="form-input"
        maxlength="1000"
        :rows="rows"
      />
      <label class="floating-label">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style  scoped>
.input {
  width: 100%;
}
.input-wrapper {
  position: relative;
}

.form-input {
  width: 100%;
  padding: 13px 16px;
  border: 1px solid #d9dbdf;
  border-radius: 8px;
  background: #fff;
  color: #1f2329;
  font-size: 16px;
  box-sizing: border-box;
}

.form-input::placeholder {
  color: #929eb0;
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 16px;
}

.form-input:focus::placeholder {
  opacity: 0;
}

.form-input:focus {
  outline: 1px solid #1cb3b8 !important;
  border: 1px solid #1cb3b8 !important;
}

.floating-label {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  color: #929eb0;
  pointer-events: none;
  transition: all 0.3s ease;
  opacity: 0;
  font-size: 14px;
}

.form-input:focus + .floating-label,
.form-input:not(:placeholder-shown) + .floating-label {
  top: 0;
  opacity: 1;
  background: linear-gradient(to bottom, #f6f8fd00 32%, white 68%);
  padding: 0 4px;
}

textarea {
  resize: none;
}
</style>>